import React, { Component } from 'react';
import api from '../api';
import './confirmAddCard.css';
import './home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import back from '../assets/images/interface/back.svg';
import inmoLogo2 from '../assets/images/inmoLogo2.png';

class ConfirmAddCard extends Component {

  // url parameters
  state = {
    status: '',
    description: '',
  };

  componentDidMount() {
    var urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');
    const description = urlParams.get('description');

    // process to get 'status' url parameter
    // let from = parameters.search('status'); // calculating where to cut (is for substr)
    // let temp = '';
    // let until = '';
    // if (from > -1) {
    //   temp = parameters.substr(from + 7); // removing everything before '?status=' (this string included)
    //   until = temp.search('&'); // calculating how many charts until '&' appears (is for substr)
    //   this.status = until > -1 ? temp.substr(0, until) : temp;
    //   this.status = this.status.replaceAll('%20', ' ');
    // }

    // repeating same process, now to get 'description' url parameter

    // from = parameters.search('description');
    // if (from > -1) {
    //   temp = parameters.substr(from + 12);
    //   until = temp.search('&');
    //   this.description = until > -1 ? temp.substr(0, until) : temp;
    //   this.description = this.description.replaceAll('%20', ' ');
    // }
    if (status !== 'add_new_card_fail') {
      api.post('v1/payments/methods/confirm/', {}).then((res) => {
        console.log(`Éxito: ${res.data?.is_success}`);
        if (!description) {
          this.setState({
            description: 'Targeta registrada exitosamente'
          })
        }
      }, err => {
        console.log(`Error: ${err.data ? err.data : err}`);
        if (!description) {
          this.setState({
            description: 'Hubo un problema al registrar su tarjeta, por favor, intentalo más adelante'
          })
        }
      });
    }
    this.setState({
      status: status ? status : 'add_new_card_fail',
      description: description,
    });
  }

  goBackToProfile = () => {
    this.props.history.replace(`${process.env.PUBLIC_URL}/profile`);
  };

  render() {
    return (
      <div className="confirmAddCard">
        <div className="home-header">
          <span onClick={this.goBackToProfile} className="home-back">
            <img className="home-back-arrow" src={back} alt="back"></img>
            <span className="home-back-text">Salir</span>
          </span>
          <img className="home-header-logo" src={inmoLogo2} alt="inmo"></img>
          <div style={{ width: '63px' }}></div>
        </div>
        <div className="confirmAddCard-container">
          {this.state.status === 'add_new_card_fail' ? (
            <div className="confirmAddCard-status">
              <FontAwesomeIcon
                style={{ marginRight: '12px' }}
                color="var(--primary-red)"
                icon={faTimes}
              />{' '}
              Error al registrar la tarjeta
            </div>
          ) : (
            <div className="confirmAddCard-status">
              <FontAwesomeIcon
                style={{ marginRight: '12px' }}
                color="var(--primary-green)"
                icon={faCheck}
              />{' '}
              Tarjeta registrada exitosamente
            </div>
          )}
          {this.state.description}
        </div>
        <div className="textCentered">
          <button
            onClick={this.goBackToProfile}
            className="confirmAddCard-button"
          >
            Aceptar
          </button>
        </div>
      </div>
    );
  }
}

export default ConfirmAddCard;
