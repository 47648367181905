import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import { GuardProvider, GuardedRoute } from "react-router-guards";

import "./App.css";
import Home from "./pages/home";
import Login from "./pages/login";
import Register from "./pages/register";
import Profile from "./pages/profile";
import ResetPassword from "./pages/resetPassword";
import EditProfile from "./pages/editProfile";
import ConfirmAddCard from "./pages/confirmAddCard";
import PaymentResponse from "./pages/paymentResponse";
import ChangePassword from "./pages/changePassword";
import TigoPaymentConfirm from "./pages/TigoPaymentConfirm";

const requireLogin = (to, from, next) => {
    if (to.meta.auth) {
        if (localStorage.getItem("inmo_access_token")) {
            next();
        }
        next.redirect(`${process.env.PUBLIC_URL}/login`);
    } else {
        next();
    }
};

function App() {
    return (
        <Router>
            <GuardProvider basename={"/loteamientos"} guards={[requireLogin]}>
                <Switch>
                    <GuardedRoute
                        exact
                        path={`${process.env.PUBLIC_URL}/`}
                        component={Home}
                    ></GuardedRoute>
                    <GuardedRoute
                        exact
                        path={`${process.env.PUBLIC_URL}/login`}
                        component={Login}
                    ></GuardedRoute>
                    <GuardedRoute
                        exact
                        path={`${process.env.PUBLIC_URL}/register`}
                        component={Register}
                    ></GuardedRoute>
                    <GuardedRoute
                        exact
                        path={`${process.env.PUBLIC_URL}/reset-password`}
                        component={ResetPassword}
                    ></GuardedRoute>
                    <GuardedRoute
                        exact
                        path={`${process.env.PUBLIC_URL}/profile`}
                        meta={{ auth: true }}
                        component={Profile}
                    ></GuardedRoute>
                    <GuardedRoute
                        exact
                        path={`${process.env.PUBLIC_URL}/profile/settings`}
                        meta={{ auth: true }}
                        component={EditProfile}
                    ></GuardedRoute>
                    <GuardedRoute
                        exact
                        path={`${process.env.PUBLIC_URL}/new-payment-method`}
                        meta={{ auth: true }}
                        component={ConfirmAddCard}
                    ></GuardedRoute>
                    <GuardedRoute
                        exact
                        path={`${process.env.PUBLIC_URL}/single-payment`}
                        meta={{ auth: true }}
                        component={PaymentResponse}
                    ></GuardedRoute>
                    <GuardedRoute
                        exact
                        path={`${process.env.PUBLIC_URL}/tigo-money-payment-confirm`}
                        meta={{ auth: true }}
                        component={TigoPaymentConfirm}
                    ></GuardedRoute>
                    <GuardedRoute
                        exact
                        path={`${process.env.PUBLIC_URL}/profile/change-password`}
                        meta={{ auth: true }}
                        component={ChangePassword}
                    ></GuardedRoute>
                </Switch>
            </GuardProvider>
        </Router>
    );
}

export default App;
