import React, { Component } from 'react';
import api from '../api';
import './home.css';
import './profile.css';

import back from '../assets/images/interface/back.svg';
import inmoLogo2 from '../assets/images/inmoLogo2.png';
import close from '../assets/images/interface/close.svg';
import closeW from '../assets/images/interface/closeWhite.svg';

class ChangePassword extends Component {
    state = {
        loading: false,
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: '',
        error: null, // popup error
        success: null // popup success
    }

    goBack = () => {
        this.props.history.goBack();
    }

    setOldPass(event) {
        this.setState({
            oldPassword: event.target.value
        })
    }

    setNewPass(event) {
        this.setState({
            newPassword: event.target.value
        })
    }

    setRepeatedPass(event) {
        this.setState({
            repeatNewPassword: event.target.value
        })
    }

    closePopUp = () => {
        this.setState({
            error: null,
            success: null,
        });
    }

    submit = () => {
        this.setState({
            loading: true
        })
        api.put('v1/users/me/change-password/', {
            old_password: this.state.oldPassword,
            password: this.state.newPassword
        }).then(() => {
            this.setState({
                oldPassword: '',
                newPassword: '',
                repeatNewPassword: '',
                loading: false,
                success: 'Los cambios se han guardado exitosamente'
            })
        }, err => {
            console.log(err);
            if (err.response.status === 400) {
                this.setState({
                    error: JSON.stringify(err.response.data),
                    loading: false
                })
            } else {
                this.setState({
                    error: 'Hubo un error desconocido al guardar sus cambios',
                    loading: false
                })
            }
        });
    }

    spinner() {
        return <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
        </div>
    }

    popUpError() {
        return <div className="popUp shadow1">
            <div className="modal-header redBackground">
                <div className="optionsModal-title whiteColor">Error</div>
                <img onClick={this.closePopUp} className="modal-close" src={closeW} alt="X"></img>
            </div>
            <div className="popUp-body">
                <div style={{ margin: '12px 0px 35px' }}>{this.state.error}</div>
                <div style={{ textAlign: 'center' }}><button onClick={this.closePopUp} className="popUp-button">Aceptar</button></div>
            </div>
        </div>
    }

    popUpSuccess() {
        return <div className="popUp shadow1">
            <div className="modal-header">
                <div className="optionsModal-title">Guardado</div>
                <img onClick={this.closePopUp} className="modal-close" src={close} alt="X"></img>
            </div>
            <div className="popUp-body">
                <div style={{ margin: '12px 0px 35px' }}>{this.state.success}</div>
                <div style={{ textAlign: 'center' }}><button onClick={this.closePopUp} className="popUp-button redColor whiteBackground shadow1">Aceptar</button></div>
            </div>
        </div>
    }

    render() {
        return <div>
            <div className="home-header">
                <span onClick={this.goBack} className="home-back">
                    <img className="home-back-arrow" src={back} alt="back"></img>
                    <span className="home-back-text" >Atrás</span>
                </span>
                <img className="home-header-logo" src={inmoLogo2} alt="inmo"></img>
                <div style={{ width: '63px' }}></div>
            </div>
            <div className="sub-title darkgrayColor textCentered">
                Cambiar contraseña
            </div>

            <div className="profile-inputs-container">
                <div className="profile-input-label">Contraseña actual</div>
                <input type="password" onChange={(event) => this.setOldPass(event)} value={this.state.oldPassword} className="profile-input mb-6"></input>
                <div className="profile-input-label">Contraseña nueva</div>
                <input type="password" onChange={(event) => this.setNewPass(event)} value={this.state.newPassword} className="profile-input mb-6"></input>
                <div className="profile-input-label">Repetir contraseña nueva</div>
                <input type="password" onChange={(event) => this.setRepeatedPass(event)} value={this.state.repeatNewPassword} className="profile-input mb-6"></input>
                {this.state.newPassword && this.state.repeatNewPassword && this.state.newPassword !== this.state.repeatNewPassword ?
                    <div style={{ marginBottom: 15, color: 'var(--primary-red)', fontSize: 14, textAlign: 'start' }}>
                        Las contraseñas no coinciden
                    </div> :
                    <div style={{ height: 33 }}></div>
                }
                <div className="textCentered">
                    <button onClick={this.submit} className="profile-button shadow1 mb-3">
                        {this.state.loading ? this.spinner() : 'Cambiar contraseña'}
                    </button>
                </div>
            </div>
            {this.state.error && this.popUpError()}
            {this.state.success && this.popUpSuccess()}
        </div>
    }
}

export default ChangePassword;
