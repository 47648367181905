import React, { Component } from 'react';
import api from '../api';
import './login.css';
import '../loader.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import inmoLogo from '../assets/images/inmoLogo2.png';
// import back from '../assets/images/interface/back.svg';
import close from '../assets/images/interface/close.svg';

class ResetPassword extends Component {
    state = {
        loading: true,
        loadingSubmit: false,
        validated: false,
        finished: false,
        error: null,
        hidePassword: true
    }

    password = React.createRef();
    token = this.props.location.search.substr(7);

    componentDidMount() {
        api.post('v1/users/password-reset/validate_token/', {
            token: this.token
        }).then(() => {
            this.setState({
                loading: false,
                validated: true
            });
        }, () => {
            this.setState({
                loading: false,
                validated: false
            });
        });
    }

    changePassword = () => {
        this.setState({
            loadingSubmit: true
        });
        api.post('v1/users/password-reset/confirm/', {
            password: this.password.current.value,
            token: this.token
        }).then(() => {
            this.setState({
                finished: true,
                loadingSubmit: false
            });
        }, err => {
            this.setState({
                loadingSubmit: false,
                error: 'Es posible que la contraseña nueva sea invalida, pruebe con otra de al menos 8 carácteres y que no se parezca a su nombre de usuario.'
            });
        });
        
    }

    triggerPasswordShowing = () => {
        this.setState({
            hidePassword: !this.state.hidePassword
        })
    }

    closePopUp = () => {
        this.setState({
            error: null
        });
    }

    goBackToLogin = () => {
        this.props.history.replace(`${process.env.PUBLIC_URL}/login`);
    }

    spinnerCenter() {
        return <div className="spinner spinner-center">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
    }

    spinner() {
        return <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
    }

    popUp () {
        return <div className="popUp shadow1">
            <div className="modal-header">
              <div className="optionsModal-title">Error</div>
              <img onClick={this.closePopUp} className="modal-close" src={close} alt="X"></img>
            </div>
            <div className="popUp-body">
                <div style={{margin: '0px 0px 25px'}}>{this.state.error}</div>
                <div style={{textAlign: 'center'}}><button onClick={this.closePopUp} className="popUp-button">Aceptar</button></div>
            </div>
        </div>
    }

    whiteBody() {
        if (!this.state.finished) {
            return <div className="white-body white-body-fixed">
                <img height="85px" style={{marginBottom: '20px'}} src={inmoLogo} alt="inmo"></img>
                <div className="login-title mb-8">Introduce tu nueva contraseña</div>
                <input ref={this.password} type={this.state.hidePassword ? 'password' : 'text'} className="login-input login-input-resetPassword mb-8" placeholder="Nueva contraseña"></input>
                <button onClick={this.triggerPasswordShowing} className="passwordShowerTrigger">
                    {this.state.hidePassword ? <FontAwesomeIcon icon={faEyeSlash}/> : <FontAwesomeIcon icon={faEye}/>}
                </button>
                <button onClick={this.changePassword} className="login-button-red shadow1 mb-8">
                    {this.state.loadingSubmit ? 
                    this.spinner() : 'Cambiar contraseña'}
                </button>
            </div>
        } else {
            return <div className="white-body white-body-fixed" style={{textAlign: 'center'}}>
                <div className="profile-list-title darkgrayColor noPaddin" style={{margin: 'calc(50vh - 180px) 0px 60px'}}>Tu contraseña se ha actualizado.</div>
                <button onClick={this.goBackToLogin} className="login-button-red shadow1 mb-8">Aceptar</button>
            </div>
        }
    }
    
    render() {
        return <div className="home-background-image">
            <div className="home-background-color">
                {this.state.loading ? 
                    this.spinnerCenter() : 
                    this.state.validated ? 
                        this.whiteBody() :
                        <h3 style={{marginTop: '0px', color: 'white', fontWeight: 400}}>La clave recibida no es valida o ya ha expirado</h3>
                }
                <div className="home-politica">Política de Privacidad</div>
            </div>
            {this.state.error ? this.popUp() : null}
        </div>
    }
}
export default ResetPassword;