import React, { Component, useEffect, useState } from "react";
import api from "../api";
import ReactMapGL, { Marker } from "react-map-gl";
import secrets from "./../secrets.json";
import "./home.css";
import "./profile.css";
import "../loader.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHistory,
  faList,
  faWallet,
  faSignOutAlt,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
// import { faFacebook, faGoogle, faInstagram } from '@fortawesome/free-brands-svg-icons';

import settingsIcon from "../assets/images/interface/settings.svg";
import back from "../assets/images/interface/back.svg";
import inmoLogo2 from "../assets/images/inmoLogo2.png";
import pCard from "../assets/images/interface/profile-card.svg";
import pGrid from "../assets/images/interface/profile-grid.svg";
import pHistory from "../assets/images/interface/profile-history.svg";
import pList from "../assets/images/interface/profile-list.svg";
import foward from "../assets/images/interface/foward.svg";
import pin from "../assets/images/mapPin.svg";
import closeW from "../assets/images/interface/closeWhite.svg";
import close from "../assets/images/interface/close.svg";
import Alert from "../components/Alert";

const bancardUrl =
  process.env.REACT_APP_BANCARD_TEST_MODE.toLowerCase() === "true"
    ? "https://vpos.infonet.com.py:8888/checkout/javascript/dist/bancard-checkout-3.0.0.js"
    : "https://vpos.infonet.com.py/checkout/javascript/dist/bancard-checkout-3.0.0.js";

function CardRegister(props) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = bancardUrl;
    const runBancard = document.createElement("script");
    runBancard.innerHTML = `Bancard.Cards.createForm('bancardIframe', '${props.bancardToken}');`;
    script.onload = () => {
      document.body.appendChild(runBancard);
    };
    document.body.appendChild(script);
  });

  return (
    <div className="modal bigModal shadow1">
      <div className="modal-header redBackground whiteColor">
        <div className="modal-title">Agrega una tarjeta de crédito</div>
        <img
          onClick={props.exit}
          className="modal-close"
          src={closeW}
          alt="X"
        ></img>
      </div>
      <div id="bancardIframe" />
    </div>
  );
}

function Checkout(props) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = bancardUrl;
    const runBancard = document.createElement("script");
    runBancard.innerHTML = `Bancard.Checkout.createForm('bancardIframe', '${props.bancardToken}');`;
    script.onload = () => {
      document.body.appendChild(runBancard);
    };
    document.body.appendChild(script);
  });

  return (
    <div className="modal bigModal shadow1">
      <div className="modal-header redBackground whiteColor">
        <div className="modal-title">Pagar con tarjeta</div>
        <img
          onClick={props.exit}
          className="modal-close"
          src={closeW}
          alt="X"
        ></img>
      </div>
      <div id="bancardIframe" />
    </div>
  );
}

function Zimple(props) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = bancardUrl;
    const runBancard = document.createElement("script");
    runBancard.innerHTML = `Bancard.Zimple.createForm('bancardIframe', '${props.bancardToken}');`;
    script.onload = () => {
      document.body.appendChild(runBancard);
    };
    document.body.appendChild(script);
  });

  return (
    <div className="modal bigModal shadow1">
      <div className="modal-header redBackground whiteColor">
        <div className="modal-title">Pagar con billetera Zimple</div>
        <img
          onClick={props.exit}
          className="modal-close"
          src={closeW}
          alt="X"
        ></img>
      </div>
      <div id="bancardIframe" />
    </div>
  );
}

function PayModal({ list, close, cards, pay }) {
  const defaultCardId = cards.find((c) => c.is_default);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [selectedCard, setSelectedCard] = useState(
    defaultCardId ? defaultCardId.id : 0
  );
  const [zimple, setZimple] = useState("false");
  const [phone, setPhone] = useState("");
  let months = [
    "",
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const getDate = function (date) {
    return `${months[parseInt(date.substr(5, 2), 10)]}, ${date.substr(0, 4)}`;
  };

  const formatNumber = function (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const getFullDate = function (date) {
    return `${
      date.substr(8, 1) === "0" ? date.substr(9, 1) : date.substr(8, 2)
    } de ${months[parseInt(date.substr(5, 2), 10)]} del ${date.substr(
      0,
      4
    )} - ${date.substr(11, 5)} hs.`;
  };

  const Spinner = function () {
    return (
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    );
  };

  return (
    <div className="modal shadow1">
      <div className="modal-header redBackground whiteColor">
        <div className="modal-title">Pagar cuotas</div>
        <img onClick={close} className="modal-close" src={closeW} alt="X"></img>
      </div>
      {success ? (
        <div className="modal-body">
          <div
            style={{
              fontSize: 20,
              fontWeight: 600,
              color:
                success.status === "success" ? "#34981a" : "var(--primary-red)",
              marginBottom: 25,
            }}
          >
            Transacción{" "}
            {success.status === "success" ? "aprobada" : "rechazada"}
          </div>
          {success.response_description &&
            success.response_description !== "" && (
              <div
                style={{
                  width: "100%",
                  padding: "6px 0px 17px",
                  color: "var(--primary-dark-gray)",
                }}
              >
                Descripción: {success.response_description}
              </div>
            )}
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              padding: "12px 0px",
              color: "var(--primary-dark-gray)",
            }}
          >
            <span>Monto</span>
            <span style={{ fontWeight: 600 }}>
              {formatNumber(Math.round(success.amount))} Gs.
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "12px 0px",
              color: "var(--primary-dark-gray)",
            }}
          >
            <span>Fecha de pago</span>
            <span style={{ fontWeight: 600 }}>
              {getFullDate(success.tx_datetime)}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "12px 0px",
              color: "var(--primary-dark-gray)",
            }}
          >
            <span>Número de transacción</span>
            <span style={{ fontWeight: 600 }}>{success.tx_id}</span>
          </div>
        </div>
      ) : (
        <div className="modal-body">
          {/*<div style={{fontSize: 20, fontWeight: 600, color: 'var(--primary-dark-gray)', marginBottom: 18}}>
                Lote {list[0].parcel?.block}-{list[0].parcel?.parcel_no} {list[0].parcel?.development?.name}
            </div>*/}
          <div
            style={{
              fontSize: 18,
              color: "var(--primary-dark-gray)",
            }}
          >
            Cuotas a pagar
          </div>
          <ul
            style={{
              fontSize: 16,
              color: "var(--primary-dark-gray)",
              marginBottom: 40,
            }}
          >
            {list.map((l, ind) => (
              <li key={ind}>
                {getDate(l.due_date)} - Lote {l.parcel?.block}-
                {l.parcel?.parcel_no}
              </li>
            ))}
          </ul>
          <label htmlFor="select-card" className="profile-input-label">
            Seleccionar método de pago
          </label>
          <select
            onChange={(event) => setSelectedCard(parseInt(event.target.value))}
            id="select-card"
            className="profile-modal-input"
          >
            <option value={0}>Otro medio de pago</option>
            {cards.map((c) => (
              <option
                key={c.id}
                selected={c.is_default}
                value={c.id}
              >{`${c.brand} - ${c.last4}`}</option>
            ))}
          </select>
          {selectedCard === 0 && (
            <div>
              <label htmlFor="select-unique" className="profile-input-label">
                Método de pago
              </label>
              <select
                value={zimple}
                onChange={(event) => setZimple(event.target.value)}
                id="select-unique"
                className="profile-modal-input"
              >
                <option value={"false"}>Tarjeta de Crédito/Débito</option>
                <option value={"true"}>Billetera Zimple</option>
                <option value={"tigo"}>Tigo Money</option>
              </select>
            </div>
          )}
          {selectedCard === 0 && (zimple === "true" || zimple === "tigo") && (
            <div>
              <label htmlFor="input-phone" className="profile-input-label">
                Celular
              </label>
              <input
                defaultValue={phone}
                onChange={(event) => setPhone(event.target.value)}
                id="input-phone"
                className="profile-modal-input"
              />
            </div>
          )}
          <div style={{ marginTop: 16 }}>
            {error && (
              <div
                style={{
                  marginBottom: 22,
                  color: "var(--primary-red)",
                  fontSize: 14,
                }}
              >
                {error}
              </div>
            )}
            <button
              onClick={() => {
                if (zimple !== "tigo") {
                  pay(
                    list,
                    selectedCard,
                    zimple === "true",
                    phone,
                    setLoading,
                    setSuccess,
                    setError
                  );
                } else {
                  setLoading(true);
                  const items = list.map((l) => {
                    return {
                      installment_no: l.installment_no,
                      contract_no: l.contract_no,
                      due_date: l.due_date,
                      order: l.order,
                    };
                  });
                  const body = {
                    line_items: items,
                    phone_number: phone,
                  };
                  api.post("v1/payments/tigo/", body).then(
                    (res) => {
                      window.location.replace(res.data.redirectUrl);
                      setLoading(false);
                    },
                    (err) => {
                      console.log(err);
                    }
                  );
                }
              }}
              className="optionsModal-button"
            >
              {loading ? <Spinner /> : "Pagar"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

class Profile extends Component {
  constructor(props) {
    super(props);
    this.pay = this.pay.bind(this);
  }
  state = {
    selectedScreen: "general", // general, debts, history, devs
    showScreen: false, // only on mobile
    showCardRegister: false, // show modal for create card register
    showCheckout: false, // show modal for checkout
    showZimple: false, // show modal for zimple
    showSupportModal: false, // show modal for contacting support
    selectedSetDefault: null, // if not null, show modal
    selectedDelete: null, // if not null, show modal

    profileData: null,
    recurrentPayments: false, // user has recurrent payments active?
    cards: [], // user payment methods,
    payments: [], // user paymentes done (history)
    debts: [], // user payment debts
    devs: [], // user developments (lotes)

    selectedDebtDate: null, // date from selected date, to pay all debts until that date
    toPayList: null, // installments list to pay, works like modal trigger too (null or not null)

    loadingProfileData: true,
    loadingPayments: true,
    loadingDebts: true,
    loadingDevs: false,
    loadingCards: true,
    loadingCardRegister: false,
    loadingRecurrentPayments: true,
    loadingDeleteCard: false,
    loadingSetCardAsDefaut: false,

    errorProfile: false,
    errorPayments: false,
    errorDebts: false,
    errorDevs: false,
    errorCards: false,
    errorCardRegister: false,
    errorDeleteCard: false,
    errorSetCardAsDefaut: false,
    errorRecurrentPayments: null,

    showModal: false, // mostrar modal de comisiones
  };

  handleOpenModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
    this.selectAndPay(this.state.debts[0]); // Ejecuta la función selectAndPay al cerrar el modal
  };

  tempMonth = null;
  bancardToken = null;

  componentDidMount() {
    api.get("v1/users/me/").then(
      (res) => {
        this.setState({
          profileData: res.data,
          loadingProfileData: false,
        });
      },
      () => {
        this.setState({
          loadingProfileData: false,
          errorProfile: true,
        });
      }
    );
    api.get("v1/payments/recurring/").then(
      (res) => {
        this.setState({
          recurrentPayments: res.data.is_active,
          loadingRecurrentPayments: false,
        });
      },
      () => {
        this.setState({
          loadingRecurrentPayments: false,
        });
      }
    );
    api.get("v1/users/me/parcels/").then(
      (res) => {
        this.setState({
          devs: res.data.features,
          loadingDevs: false,
        });
      },
      () => {
        this.setState({
          loadingDevs: false,
          errorDevs: true,
        });
      }
    );
    api.get("v1/payments/installments/").then(
      (res) => {
        this.setState({
          debts: res.data
            .sort((a, b) => a.installment_no - b.installment_no)
            .sort((a, b) => a.contract_no - b.contract_no)
            .sort((a, b) => new Date(a.due_date) - new Date(b.due_date)),
          loadingDebts: false,
        });
      },
      () => {
        this.setState({
          loadingDebts: false,
          errorDebts: true,
        });
      }
    );
    api.get("v1/payments/history/").then(
      (res) => {
        this.setState({
          loadingPayments: false,
          payments: res.data,
        });
      },
      () => {
        this.setState({
          loadingPayments: false,
          errorPayments: true,
        });
      }
    );
    api.get("v1/payments/methods/").then(
      (res) => {
        this.setState({
          cards: res.data,
          loadingCards: false,
        });
      },
      () => {
        this.setState({
          loadingCards: false,
          errorCards: true,
        });
      }
    );
  }

  removeFromArray(arr, value) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      }
    }
    return arr;
  }

  goBack = () => {
    if (this.state.showScreen && window.innerWidth <= 800) {
      this.setState({
        showScreen: false,
        selectedScreen: "general",
      });
    } else {
      this.props.history.goBack();
    }
  };

  goToSettings = () => {
    this.props.history.push(`${process.env.PUBLIC_URL}/profile/settings`);
  };

  selectDebtDate(date) {
    this.setState({
      selectedDebtDate: date,
    });
  }

  openCardRegister = () => {
    this.setState({
      loadingCardRegister: true,
    });
    api
      .post("v1/payments/methods/", {
        redirect_url: `${window.location.origin}${process.env.PUBLIC_URL}/new-payment-method`,
      })
      .then(
        (res) => {
          this.bancardToken = res.data.process_id;
          this.setState({
            loadingCardRegister: false,
            showCardRegister: true,
          });
        },
        () => {
          this.setState({
            loadingCardRegister: false,
            errorCardRegister: true,
          });
        }
      );
  };
  closeCardRegister = () => {
    this.setState({
      showCardRegister: false,
    });
  };
  closeCheckout = () => {
    this.setState({
      showCheckout: false,
    });
  };
  closeZimple = () => {
    this.setState({
      showZimple: false,
    });
  };

  triggerSupportModal = () => {
    this.setState({
      showSupportModal: !this.state.showSupportModal,
    });
  };

  selectScreen(screen) {
    if (this.state.selectedScreen !== screen) {
      this.setState({
        selectedScreen: screen,
        showScreen: window.innerWidth <= 800 ? true : this.state.showScreen,
      });
      switch (screen) {
        case "general":
          /*this.setState({
                                  loadingDebts: true,
                                  loadingCards: true,
                                  loadingRecurrentPayments: true,
                              })*/
          api.get("v1/payments/recurring/").then(
            (res) => {
              this.setState({
                recurrentPayments: res.data.is_active,
                loadingRecurrentPayments: false,
              });
            },
            () => {
              /*this.setState({
                                          loadingRecurrentPayments: false,
                                      });*/
            }
          );
          api.get("v1/payments/installments/").then(
            (res) => {
              this.setState({
                debts: res.data
                  .sort((a, b) => a.installment_no - b.installment_no)
                  .sort((a, b) => a.contract_no - b.contract_no)
                  .sort((a, b) => new Date(a.due_date) - new Date(b.due_date)),
                errorDebts: false,
                loadingDebts: false,
              });
            },
            () => {
              /*this.setState({
                                          loadingDebts: false,
                                          errorDebts: true
                                      });*/
            }
          );
          api.get("v1/users/me/parcels/").then(
            (res) => {
              this.setState({
                devs: res.data.features,
                errorDevs: false,
                loadingDevs: false,
              });
            },
            () => {
              /*this.setState({
                                          loadingDevs: false,
                                          errorDevs: true
                                      });*/
            }
          );
          break;
        case "debts":
          /*this.setState({
                                  loadingDebts: true,
                              })*/
          api.get("v1/payments/installments/").then(
            (res) => {
              this.setState({
                debts: res.data
                  .sort((a, b) => a.installment_no - b.installment_no)
                  .sort((a, b) => a.contract_no - b.contract_no)
                  .sort((a, b) => new Date(a.due_date) - new Date(b.due_date)),
                errorDebts: false,
                loadingDebts: false,
              });
            },
            () => {
              /*this.setState({
                                          loadingDebts: false,
                                          errorDebts: true
                                      });*/
            }
          );
          break;
        case "history":
          /*this.setState({
                                  loadingPayments: true,
                              })*/
          api.get("v1/payments/history/").then(
            (res) => {
              this.setState({
                loadingPayments: false,
                errorPayments: false,
                payments: res.data,
              });
            },
            () => {
              /*this.setState({
                                          loadingPayments: false,
                                          errorPayments: true
                                      });*/
            }
          );
          break;
        case "devs":
          /*this.setState({
                                  loadingDevs: true,
                              })*/
          api.get("v1/users/me/parcels/").then(
            (res) => {
              this.setState({
                devs: res.data.features,
                errorDevs: false,
                loadingDevs: false,
              });
            },
            () => {
              /*this.setState({
                                          loadingDevs: false,
                                          errorDevs: true
                                      });*/
            }
          );
          break;
        case "cards":
          /*this.setState({
                                  loadingCards: true,
                              })*/
          api.get("v1/payments/methods/").then(
            (res) => {
              this.setState({
                cards: res.data,
                errorCards: false,
                loadingCards: false,
              });
            },
            () => {
              /*this.setState({
                                          loadingCards: false,
                                          errorCards: true
                                      });*/
            }
          );
          break;
        default:
          break;
      }
    }
  }

  logout = () => {
    localStorage.removeItem("inmo_access_token");
    localStorage.removeItem("inmo_refresh_token");
    this.props.history.replace(`${process.env.PUBLIC_URL}/login`);
  };

  formatNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  getDate(date) {
    let months = [
      "",
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    return `${
      date.substr(8, 1) === "0" ? date.substr(9, 1) : date.substr(8, 2)
    } de ${months[parseInt(date.substr(5, 2), 10)]} del ${date.substr(0, 4)}`;
  }

  getMonthTitle(date) {
    let months = [
      "",
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    if (
      this.tempMonth === null ||
      this.tempMonth !== `${date.substr(0, 4)}-${date.substr(5, 2)}`
    ) {
      const firstTitle = this.tempMonth === null;
      this.tempMonth = `${date.substr(0, 4)}-${date.substr(5, 2)}`;
      return (
        <div className={`profile-list-date ${firstTitle ? "mt-0" : "e"}`}>
          {" "}
          {months[parseInt(date.substr(5, 2), 10)]} {date.substr(0, 4)}
        </div>
      );
    }
  }

  showDeleteModal(card) {
    this.setState({
      selectedDelete: card,
    });
  }

  showSetCurrentModal(card) {
    this.setState({
      selectedSetDefault: card,
    });
  }

  closeModals = () => {
    this.setState({
      selectedSetDefault: null,
      selectedDelete: null,
    });
  };

  deletePaymentMethod(cardId) {
    this.setState({
      loadingDeleteCard: true,
      errorDeleteCard: false,
    });
    api.delete(`v1/payments/methods/${cardId}/`).then(
      (res) => {
        this.setState({
          loadingDeleteCard: true,
          errorDeleteCard: false,
        });
        api.delete(`v1/payments/methods/${cardId}/`).then(
          (res) => {
            let newCards = this.state.cards.slice(0); // copying new card array
            const indexToDelete = newCards.findIndex((c) => c.id === cardId);
            newCards = newCards.splice(indexToDelete, 1);

            this.setState({
              loadingDeleteCard: false,
              selectedDelete: null,
              cards: newCards,
            });
          },
          () => {
            this.setState({
              loadingDeleteCard: false,
              errorDeleteCard: true,
            });
          }
        );
      },
      () => {
        this.setState({
          loadingDeleteCard: false,
          errorDeleteCard: true,
        });
      }
    );
  }

  setPaymentMethodAsDefault(cardId) {
    this.setState({
      loadingSetCardAsDefaut: true,
      errorSetCardAsDefaut: false,
    });
    api.put(`v1/payments/methods/default/`, { card_id: cardId }).then(
      (res) => {
        // changing the default card in render
        let cards = this.state.cards;
        if (res.data.status && res.data.status === "success") {
          const oldDefault = cards.findIndex((c) => c.is_default);
          const newDefault = cards.findIndex((c) => c.id === cardId);
          if (oldDefault >= 0) {
            cards[oldDefault].is_default = false;
          }
          cards[newDefault].is_default = true;
        }
        this.setState({
          loadingSetCardAsDefaut: false,
          selectedSetDefault: null,
          cards: cards,
        });
      },
      () => {
        this.setState({
          loadingSetCardAsDefaut: false,
          errorSetCardAsDefaut: true,
        });
      }
    );
  }

  triggerRecurrentPayments = () => {
    this.setState({
      loadingRecurrentPayments: true,
      errorRecurrentPayments: null,
    });
    if (this.state.recurrentPayments === false) {
      api.post("v1/payments/recurring/", {}).then(
        (res) => {
          this.setState({
            recurrentPayments: res.data.is_active,
            loadingRecurrentPayments: false,
          });
        },
        (err) => {
          console.log(err.response);
          this.setState({
            loadingRecurrentPayments: false,
            errorRecurrentPayments: err.response.data[0],
          });
        }
      );
    } else {
      api.delete("v1/payments/recurring/").then(
        () => {
          this.setState({
            recurrentPayments: false,
            loadingRecurrentPayments: false,
          });
        },
        () => {
          this.setState({
            loadingRecurrentPayments: false,
          });
        }
      );
    }
  };

  selectAndPay(debt) {
    let debtToPay = debt;
    debtToPay.order = 1;
    const list = [debtToPay];
    this.setState({
      toPayList: list,
    });
  }

  setToPayList(/*date, parcelId*/) {
    // pay all selected and previous installments from selected parcel

    // all debts from same dev and current and previous date
    // this.state.debts.filter(d => d.parcel?.id === parcelId).filter(de => de.due_date <= date);

    let list = this.state.debts.filter(
      (de) => de.due_date <= this.state.selectedDebtDate
    );
    list = list.map((l, i) => {
      return {
        ...l,
        order: i + 1,
      };
    });
    this.setState({
      toPayList: list,
    });
  }

  pay(list, cardId, zimple, phone, setLoading, setSuccess, setError) {
    setLoading(true);
    setError(null);
    const items = list.map((l) => {
      return {
        installment_no: l.installment_no,
        contract_no: l.contract_no,
        due_date: l.due_date,
        order: l.order,
      };
    });
    if (cardId > 0) {
      // pay with card
      const body = {
        payment_method: cardId,
        line_items: items,
      };
      api.post("v1/payments/charge/", body).then(
        (res) => {
          console.log(res.data);
          setLoading(false);
          setSuccess(res.data);
          api.get("v1/payments/installments/").then(
            (ins) => {
              this.setState({
                debts: ins.data
                  .sort((a, b) => a.installment_no - b.installment_no)
                  .sort((a, b) => a.contract_no - b.contract_no)
                  .sort((a, b) => new Date(a.due_date) - new Date(b.due_date)),
                errorDebts: false,
                loadingDebts: false,
              });
            },
            () => {
              console.log("error al actualizar las cuotas");
            }
          );
        },
        (err) => {
          console.log("ERROR AL REALIZAR PAGO");
          setLoading(false);
          if (err.response && err.response.status === 500) {
            setError("Operacion fallida, por favor pruebe en unos minutos");
          } else {
            setError("Error en el pago, por favor pruebe en unos minutos");
          }
        }
      );
    } else {
      // single pay
      const body = {
        line_items: items,
        return_url: `${window.location.origin}${process.env.PUBLIC_URL}/single-payment`,
        cancel_url: `${window.location.origin}${process.env.PUBLIC_URL}/single-payment`,
        zimple: zimple,
      };
      if (zimple) {
        body["phone_number"] = phone;
      }
      api.post("v1/payments/single-buy/", body).then(
        (res) => {
          this.bancardToken = res.data.process_id;
          setLoading(false);
          this.setState({
            showCheckout: !zimple,
            showZimple: zimple,
          });
        },
        (err) => {
          setLoading(false);
          console.log("ERROR AL OBTENER BANCARD TOKEN");
          if (err.response && err.response.status === 500) {
            setError("Operacion fallida, por favor pruebe en unos minutos");
          } else {
            setError(
              "Hubo un error al establecer conexión con Bancard, por favor inténtelo más adelante"
            );
          }
        }
      );
    }
  }

  getTotalToPay() {
    if (this.state.selectedDebtDate) {
      let total = 0;
      for (let p of this.state.debts) {
        if (p.due_date <= this.state.selectedDebtDate) {
          total = total + Math.round(p.arrears_amount) + Math.round(p.amount);
        } else {
          break;
        }
      }
      return `${this.formatNumber(total)} Gs.`;
    } else {
      return "0 Gs.";
    }
  }

  spinner() {
    return (
      <div className="spinner spinner-red">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    );
  }

  spinnerW() {
    return (
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    );
  }

  listScreen() {
    return (
      <div className="profile-listScreen desktop-noDisplay">
        {this.listScreenContent()}
      </div>
    );
  }

  deleteModal() {
    return (
      <div className="popUp shadow1">
        <div className="modal-header redBackground">
          <div className="optionsModal-title whiteColor">Advertencia</div>
          <img
            onClick={this.closeModals}
            className="modal-close"
            src={closeW}
            alt="X"
          ></img>
        </div>
        <div className="popUp-body">
          <div style={{ margin: "12px 0px 35px", fontSize: "14px" }}>
            <div style={{ marginBottom: "18px", fontSize: "16px" }}>
              ¿Estás seguro que quieres eliminar esta tarjeta como método de
              pago?
            </div>
            <div>**** **** **** {this.state.selectedDelete.last_4}</div>
            {this.state.errorDeleteCard && (
              <div
                style={{
                  color: "var(--primary-red)",
                  marginTop: "15px",
                }}
              >
                Hubo un error al eliminar esta tarjeta, por favor inténtalo más
                adelante
              </div>
            )}
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              onClick={() =>
                this.deletePaymentMethod(this.state.selectedDelete.id)
              }
              className="popUp-button"
            >
              {this.state.loadingDeleteCard ? this.spinnerW() : "Aceptar"}
            </button>
          </div>
        </div>
      </div>
    );
  }

  setDefaultModal() {
    return (
      <div className="popUp shadow1">
        <div className="modal-header">
          <div className="optionsModal-title">Aviso</div>
          <img
            onClick={this.closeModals}
            className="modal-close"
            src={close}
            alt="X"
          ></img>
        </div>
        <div className="popUp-body">
          <div style={{ margin: "12px 0px 35px", fontSize: "14px" }}>
            <div style={{ marginBottom: "18px", fontSize: "16px" }}>
              ¿Quieres cambiar tu método de pago a esta tarjeta?
            </div>
            <div>**** **** **** {this.state.selectedSetDefault.last4}</div>
            {this.state.errorSetCardAsDefaut && (
              <div
                style={{
                  color: "var(--primary-red)",
                  marginTop: "15px",
                }}
              >
                Hubo un error al seleccionar esta tarjeta como método por
                defecto, por favor inténtalo más adelante
              </div>
            )}
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              onClick={() =>
                this.setPaymentMethodAsDefault(this.state.selectedSetDefault.id)
              }
              className="popUp-button"
            >
              {this.state.loadingSetCardAsDefaut ? this.spinnerW() : "Aceptar"}
            </button>
          </div>
        </div>
      </div>
    );
  }

  supportModal() {
    return (
      <div className="popUp shadow1" style={{ position: "fixed" }}>
        <div className="modal-header">
          <div className="optionsModal-title">Obtén ayuda</div>
          <img
            onClick={this.triggerSupportModal}
            className="modal-close"
            src={close}
            alt="X"
          ></img>
        </div>
        <div className="popUp-body">
          <div style={{ padding: "0px 12px", fontSize: 14 }}>
            Si has experimentado algun inconveniente con la plataforma, puedes
            ponerte en contacto con nosotros a través de:
            <ul>
              <li>Correo: info@inmo.com.py</li>
              <li>Teléfono: (021) 695-685/6</li>
              <li>Celular: 0981-780-144</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  listScreenContent() {
    switch (this.state.selectedScreen) {
      case "general":
        return (
          <div
            className="profile-list textCentered"
            style={{ paddingLeft: 20, paddingRight: 20 }}
          >
            <div className="profile-title darkgrayColor mb-3 mt-9">
              Cantidad de lotes adquiridos:
            </div>
            {this.state.loadingDevs ? (
              <div style={{ margin: "40px 0px 60px" }}>
                {this.spinner()}
                <div style={{ marginTop: "12px" }}>Cargando lotes...</div>
              </div>
            ) : this.state.errorDevs ? (
              <div
                style={{
                  color: "var(--primary-red)",
                  fontSize: "16px",
                  padding: "30px 30px 70px",
                  textAlign: "start",
                }}
              >
                Error al traer los lotes adquiridos, inténtelo más adelante...
              </div>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "13px",
                  }}
                >
                  <span className="profile-lotesAmount redColor">
                    {this.state.devs.length}
                  </span>
                  <span style={{ fontSize: "30px" }} className="redColor">
                    Lotes
                  </span>
                </div>
                <div
                  style={{
                    paddingBottom: "70px",
                    margin: "0px 20px",
                    borderBottom: "2px solid white",
                  }}
                >
                  <button
                    onClick={() =>
                      this.state.devs.length > 0
                        ? this.selectScreen("devs")
                        : this.props.history.replace(
                            `${process.env.PUBLIC_URL}`
                          )
                    }
                    className="profile-lotesButton shadow2"
                  >
                    Ver {this.state.devs.length === 0 && " lotes disponibles"}
                  </button>
                </div>
              </div>
            )}
            <div className="profile-pagosRecurrentes">
              <div className="profile-title darkgrayColor mb-3 mt-1">
                Pagos automáticos
              </div>
              <div style={{ marginBottom: "22px" }}>
                {this.state.recurrentPayments
                  ? "El servicio de “Pago Automático” se encuentra activo. Con esta funcionalidad tus pagos se procesan de forma automática antes de cada vencimiento."
                  : "¡Activá el servicio de “Pago Automático” para no atrasarte con ninguna cuota! Con esta funcionalidad tus pagos se procesarán de forma automática antes de cada vencimiento."}
              </div>
              {this.state.recurrentPayments ? (
                <div
                  onClick={this.triggerRecurrentPayments}
                  className="profile-link"
                >
                  {this.state.loadingRecurrentPayments
                    ? this.spinner()
                    : this.state.recurrentPayments
                    ? "Desactivar pagos automáticos"
                    : "Activar pagos automáticos"}
                </div>
              ) : (
                <button
                  onClick={this.triggerRecurrentPayments}
                  className="login-button-red shadow1"
                >
                  {this.state.loadingRecurrentPayments
                    ? this.spinnerW()
                    : this.state.recurrentPayments
                    ? "Desactivar pagos automáticos"
                    : "Activar pagos automáticos"}
                </button>
              )}
              {this.state.errorRecurrentPayments && (
                <div
                  style={{
                    color: "var(--primary-red)",
                    fontSize: "16px",
                    paddingTop: 12,
                  }}
                >
                  {this.state.errorRecurrentPayments}
                </div>
              )}
            </div>
            <div className="profile-overlineTitle mb-4">
              proximo vencimiento
            </div>
            <div className="profile-line"></div>

            {this.state.loadingDebts ? (
              <div>
                {this.spinner()}
                <div style={{ marginTop: "12px" }}>
                  Cargando cuotas a pagar...
                </div>
              </div>
            ) : this.state.errorDebts ? (
              <div
                style={{
                  color: "var(--primary-red)",
                  fontSize: "16px",
                  padding: "12px 20px",
                  textAlign: "start",
                }}
              >
                Error al traer las cuotas pendientes, inténtelo más adelante...
              </div>
            ) : this.state.debts.length > 0 ? (
              <div className="profile-quote-card">
                <div>
                  <div className="profile-subtitle mb-1">
                    {this.state.debts[0].parcel?.development.name}
                  </div>
                  <div className="profile-title mb-5">
                    {this.getDate(this.state.debts[0].due_date)}
                  </div>
                  <div className="profile-subtitle mb-0">
                    Lote #{this.state.debts[0].parcel?.parcel_no} - Cuota{" "}
                    {this.state.debts[0].installment_no}
                  </div>
                </div>
                <div style={{ textAlign: "end" }}>
                  <div className="profile-quote-price mb-5">
                    Gs.{" "}
                    {this.formatNumber(
                      Math.round(
                        parseInt(this.state.debts[0].amount) +
                          parseInt(this.state.debts[0].arrears_amount)
                      )
                    )}
                  </div>
                  <button
                    onClick={() => {
                      if (
                        this.state.debts[0].commissions.bancard ||
                        this.state.debts[0].commissions.tigo
                      ) {
                        this.handleOpenModal();
                      } else {
                        this.selectAndPay(this.state.debts[0]);
                      }
                    }}
                    className="profile-status"
                  >
                    Pagar
                  </button>
                  {this.state.showModal && (
                    <Alert
                      message={`El pago online contará con un recargo con ${
                        this.state.debts[0].commissions.bancard &&
                        this.state.debts[0].commissions.tigo
                          ? "Bancard y Tigo"
                          : this.state.debts[0].commissions.bancard
                          ? "Bancard"
                          : "Tigo"
                      }`}
                      onClose={() => this.handleCloseModal()}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div
                style={{
                  textAlign: "start",
                  padding: "12px 20px",
                }}
              >
                ¡Bien, no tienes cuotas pendientes!
              </div>
            )}
          </div>
        );
      case "debts":
        return (
          <div className="profile-list" style={{ paddingBottom: 0 }}>
            <div className="profile-list-title">Cuotas a pagar</div>
            {this.state.loadingDebts ? (
              <div className="textCentered" style={{ margin: "40px 0px 60px" }}>
                {this.spinner()}
                <div style={{ marginTop: "12px" }}>
                  Cargando cuotas a pagar...
                </div>
              </div>
            ) : this.state.errorDebts ? (
              <div
                style={{
                  color: "var(--primary-red)",
                  fontSize: "16px",
                  padding: "40px 30px",
                  textAlign: "start",
                }}
              >
                Error al traer las cuotas pendientes, inténtelo más adelante...
              </div>
            ) : this.state.debts.length > 0 ? (
              <div className="profile-list-card-container profile-list-debts">
                {(this.tempMonth = null)}
                {this.state.debts.map((d, ind) => (
                  <div key={ind}>
                    {this.getMonthTitle(d.due_date)}
                    <div className="profile-quote-card mb-3">
                      <div>
                        <div className="profile-subtitle mb-1">
                          {d.parcel?.development?.name}
                        </div>
                        <div className="profile-title mb-5">
                          {this.getDate(d.due_date)}
                        </div>
                        <div className="profile-subtitle mb-0">
                          Lote #{d.parcel?.parcel_no} - Cuota {d.installment_no}
                        </div>
                      </div>
                      <div style={{ textAlign: "end" }}>
                        <div className="profile-quote-price mb-5">
                          Gs.{" "}
                          {this.formatNumber(
                            Math.round(
                              parseInt(d.amount) + parseInt(d.arrears_amount)
                            )
                          )}
                        </div>
                        {this.state.selectedDebtDate >= d.due_date ? (
                          <button
                            onClick={() => this.selectDebtDate(d.due_date)}
                            className="profile-status profile-status-selected"
                          >
                            Seleccionado
                          </button>
                        ) : (
                          <button
                            onClick={() => this.selectDebtDate(d.due_date)}
                            className="profile-status"
                          >
                            Seleccionar
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div
                style={{
                  textAlign: "start",
                  padding: "40px 30px",
                }}
              >
                ¡Bien, no tienes cuotas pendientes!
              </div>
            )}
            {this.state.debts.length > 0 && (
              <div className="profile-list-footer">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: 77,
                  }}
                >
                  <div style={{ marginRight: 10 }}>
                    <div
                      className="profile-subtitle darkgrayColor"
                      style={{ fontWeight: 700 }}
                    >
                      TOTAL
                    </div>
                    <div
                      style={{
                        color: "var(--primary-dark-gray)",
                        fontSize: 16,
                        fontWeight: 700,
                      }}
                    >
                      {this.getTotalToPay()}
                    </div>
                  </div>
                  <button
                    onClick={() => this.setToPayList()}
                    disabled={!this.state.selectedDebtDate}
                    className="profile-list-card-payButton"
                  >
                    Pagar cuota/s
                  </button>
                </div>
              </div>
            )}
          </div>
        );
      case "history":
        return (
          <div className="profile-list">
            <div className="profile-list-title">Historial de pagos</div>
            {this.state.loadingPayments ? (
              <div className="textCentered" style={{ margin: "40px 0px 60px" }}>
                {this.spinner()}
                <div style={{ marginTop: "12px" }}>
                  Cargando historial de pagos...
                </div>
              </div>
            ) : this.state.errorPayments ? (
              <div
                style={{
                  color: "var(--primary-red)",
                  fontSize: "16px",
                  padding: "40px 30px",
                  textAlign: "start",
                }}
              >
                Error al traer el historial de pagos, inténtelo más adelante...
              </div>
            ) : this.state.payments.length > 0 ? (
              <div className="profile-list-card-container">
                {(this.tempMonth = null)}
                {this.state.payments.map((p, ind) => (
                  <div key={ind}>
                    {this.getMonthTitle(p.payment_date)}
                    <div className="profile-list-card shadow2">
                      <div className="profile-list-card-header">
                        <div>
                          <div className="profile-subtitle mb-1 darkgrayColor">
                            {p.parcel.development.name}
                          </div>
                          <div className="profile-title darkgrayColor">
                            {this.getDate(p.payment_date)}
                          </div>
                        </div>
                        <div className="redColor">
                          <div
                            style={{
                              textAlign: "end",
                            }}
                            className="profile-subtitle mb-1 redColor"
                          >
                            Lote {p.parcel.block}-{p.parcel.parcel_no}
                          </div>
                          <div
                            style={{
                              textAlign: "end",
                            }}
                            className="profile-quote-card-title"
                          >
                            Cuota #{p.installment_no}
                          </div>
                        </div>
                      </div>
                      <div className="profile-list-card-footer">
                        <div style={{ fontSize: "14px" }}>
                          <span
                            style={{
                              fontWeight: 600,
                            }}
                          >
                            Contrato:
                          </span>{" "}
                          {p.contract_no}
                        </div>
                        <div className="profile-quote-price redColor">
                          Gs. {this.formatNumber(Math.round(p.total_amount))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div
                style={{
                  textAlign: "start",
                  padding: "40px 30px",
                }}
              >
                No tienes pagos realizados ultimamente
              </div>
            )}
            <div
              style={{
                margin: "48px 40px 0px",
                fontSize: "10px",
                textAlign: "center",
              }}
            >
              Solo a modo de referencia. Las cuotas pagadas pueden demorar hasta
              48 horas habiles en mostrarse
            </div>
          </div>
        );
      case "devs":
        return (
          <div className="profile-list">
            <div className="profile-list-title">Lotes adquiridos</div>
            {this.state.loadingDevs ? (
              <div className="textCentered" style={{ margin: "40px 0px 60px" }}>
                {this.spinner()}
                <div style={{ marginTop: "12px" }}>Cargando lotes...</div>
              </div>
            ) : this.state.errorDevs ? (
              <div
                style={{
                  color: "var(--primary-red)",
                  fontSize: "16px",
                  padding: "40px 30px",
                  textAlign: "start",
                }}
              >
                Error al traer los lotes adquiridos, inténtelo más adelante...
              </div>
            ) : this.state.devs.length > 0 ? (
              <div className="profile-list-card-container">
                {this.state.devs.map((d, i) => (
                  <div key={i} className="profile-dev-card shadow2">
                    <div className="profile-dev-card-map">
                      {d.geometry && (
                        <ReactMapGL
                          mapboxApiAccessToken={secrets.mapbox_access_token}
                          attributionControl={false}
                          mapStyle="mapbox://styles/ramirosilvero/ckjww1eo715ft17pp4diawhx5"
                          width="100%"
                          height="100%"
                          latitude={d.geometry.coordinates[0][0][1]}
                          longitude={d.geometry.coordinates[0][0][0]}
                          zoom={14}
                        >
                          <Marker
                            latitude={d.geometry.coordinates[0][0][1]}
                            longitude={d.geometry.coordinates[0][0][0]}
                          >
                            <img
                              style={{
                                transform: "translate(-50%, -100%)",
                              }}
                              src={pin}
                              alt="x"
                            ></img>
                          </Marker>
                        </ReactMapGL>
                      )}
                    </div>
                    <div className="profile-dev-card-body">
                      <div className="profile-dev-card-title">
                        Lote {d.properties.block}-{d.properties.parcel_no}
                      </div>
                      <div className="profile-dev-card-details">
                        <div className="profile-dev-card-detail">
                          <div className="profile-dev-card-subtitle">
                            Loteamiento
                          </div>
                          <div className="profile-dev-card-data">
                            {d.properties.name}
                          </div>
                        </div>
                        {/*<div className="profile-dev-card-detail">
                                                    <div className="profile-dev-card-subtitle">Precio</div>
                                                    <div className="profile-dev-card-data">{d.properties.currency} {this.formatNumber(d.properties.monthly_payment)}</div>
                                                </div>*/}
                        <div className="profile-dev-card-detail">
                          <div className="profile-dev-card-subtitle">
                            Superficie
                          </div>
                          <div className="profile-dev-card-data">
                            {d.properties.area} m²
                          </div>
                        </div>
                        {/*<div className="profile-dev-card-detail">
                                                    <div className="profile-dev-card-subtitle">Cuotas</div>
                                                    <div className="profile-dev-card-data">{d.properties.installments}</div>
                                                </div>*/}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div
                style={{
                  textAlign: "start",
                  padding: "40px 30px",
                }}
              >
                No tienes lotes adquiridos
              </div>
            )}
          </div>
        );
      case "cards":
        return (
          <div className="profile-list">
            <div className="profile-list-title">Métodos de pago</div>
            {this.state.loadingCards ? (
              <div className="textCentered" style={{ margin: "40px 0px 60px" }}>
                {this.spinner()}
                <div style={{ marginTop: "12px" }}>
                  Cargando métodos de pago...
                </div>
              </div>
            ) : this.state.errorCards ? (
              <div
                style={{
                  color: "var(--primary-red)",
                  fontSize: "16px",
                  padding: "40px 30px",
                  textAlign: "start",
                }}
              >
                Error al traer tus métodos de pago, inténtelo más adelante...
              </div>
            ) : this.state.cards.length > 0 ? (
              <div className="profile-list-paymentMethods-container">
                {this.state.cards.map((c, ind) => (
                  <div className="profile-list-card shadow2" key={ind}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "18px",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            fontSize: "18px",
                            fontWeight: 600,
                            marginBottom: "14px",
                          }}
                        >
                          ****&nbsp;****&nbsp;****&nbsp;
                          {c.last4}
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          {`Valido hasta ${
                            c.exp_month.length > 1
                              ? c.exp_month
                              : `0${c.exp_month}`
                          }/${c.exp_year}`}
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "var(--primary-dark-gray)",
                          marginLeft: "15px",
                        }}
                      >
                        {c.brand}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        disabled={c.is_default}
                        onClick={() => this.showSetCurrentModal(c)}
                        className="profile-button-mini-white"
                      >
                        {c.is_default
                          ? "Predeterminado"
                          : "Usar como predeterminado"}
                      </button>
                      <button
                        onClick={() => this.showDeleteModal(c)}
                        className="profile-button-mini-red"
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div
                style={{
                  textAlign: "start",
                  padding: "40px 30px",
                }}
              >
                Aún no tienes tarjetas cargadas
              </div>
            )}
            <div
              style={{
                margin: "40px 0px 30px",
                textAlign: "center",
              }}
            >
              <button
                onClick={this.openCardRegister}
                className="profile-button shadow1"
              >
                {this.state.loadingCardRegister
                  ? this.spinner()
                  : "Agregar tarjeta"}
              </button>
            </div>
            <div
              style={{
                margin: "0px 40px",
                fontSize: "10px",
                textAlign: "center",
              }}
            >
              La carga de los métodos de pago pueden estar sujeto a retrasos
              debido a procesos de terceros
            </div>
          </div>
        );
      default:
        return <div></div>;
    }
  }

  whiteBody() {
    return (
      <div>
        {/*If display is desktop*/}
        <div className="profile-whiteBodyDesktop mobile-noDisplay">
          <div
            onClick={() => this.selectScreen("general")}
            className={`profile-menuOption profile-menuOption-border ${
              this.state.selectedScreen === "general"
                ? "profile-menuOption-selected"
                : ""
            }`}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={pGrid} alt="card" style={{ marginRight: "40px" }}></img>
              General
            </div>
            {this.state.selectedScreen === "general" && (
              <img src={foward} alt="->"></img>
            )}
          </div>
          <div
            onClick={() => this.selectScreen("debts")}
            className={`profile-menuOption profile-menuOption-border ${
              this.state.selectedScreen === "debts"
                ? "profile-menuOption-selected"
                : ""
            }`}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={pCard} alt="card" style={{ marginRight: "40px" }}></img>
              Pagar cuotas
            </div>
            {this.state.selectedScreen === "debts" && (
              <img src={foward} alt="->"></img>
            )}
          </div>
          <div
            onClick={() => this.selectScreen("history")}
            className={`profile-menuOption profile-menuOption-border ${
              this.state.selectedScreen === "history"
                ? "profile-menuOption-selected"
                : ""
            }`}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={pHistory}
                alt="card"
                style={{ marginRight: "40px" }}
              ></img>
              Historial de cuotas
            </div>
            {this.state.selectedScreen === "history" && (
              <img src={foward} alt="->"></img>
            )}
          </div>
          <div
            onClick={() => this.selectScreen("devs")}
            className={`profile-menuOption profile-menuOption-border ${
              this.state.selectedScreen === "devs"
                ? "profile-menuOption-selected"
                : ""
            }`}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={pList} alt="card" style={{ marginRight: "40px" }}></img>
              Lista de lotes
            </div>
            {this.state.selectedScreen === "devs" && (
              <img src={foward} alt="->"></img>
            )}
          </div>
          <div
            onClick={() => this.selectScreen("cards")}
            className={`profile-menuOption profile-menuOption-border ${
              this.state.selectedScreen === "cards"
                ? "profile-menuOption-selected"
                : ""
            }`}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FontAwesomeIcon
                style={{
                  marginRight: "40px",
                  width: "32px",
                  height: "32px",
                  color: "var(--primary-red)",
                }}
                icon={faWallet}
              />
              Métodos de pago
            </div>
            {this.state.selectedScreen === "cards" && (
              <img src={foward} alt="->"></img>
            )}
          </div>
          <div
            onClick={this.triggerSupportModal}
            className={`profile-menuOption`}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FontAwesomeIcon
                style={{
                  marginRight: "40px",
                  width: "32px",
                  height: "32px",
                  color: "var(--primary-red)",
                }}
                icon={faQuestion}
              />
              Obtener soporte
            </div>
          </div>
        </div>
        {/*If display is mobile*/}
        <div className="profile-whiteBodyMobile desktop-noDisplay">
          <div className="profile-optionsIcons desktop-noDisplay">
            <div className="profile-optionContainer">
              <button
                onClick={() => this.selectScreen("debts")}
                className="profile-optionsIcon shadow1"
              >
                <FontAwesomeIcon icon={faCreditCard} />
              </button>
              <div className="profile-options-title">Pagar cuotas</div>
            </div>
            <div className="profile-optionContainer">
              <button
                onClick={() => this.selectScreen("history")}
                className="profile-optionsIcon shadow1"
              >
                <FontAwesomeIcon icon={faHistory} />
              </button>
              <div className="profile-options-title">Historial de pagos</div>
            </div>
            <div className="profile-optionContainer">
              <button
                onClick={() => this.selectScreen("devs")}
                className="profile-optionsIcon shadow1"
              >
                <FontAwesomeIcon icon={faList} />
              </button>
              <div className="profile-options-title">Lista de lotes</div>
            </div>
            <div className="profile-optionContainer">
              <button
                onClick={() => this.selectScreen("cards")}
                className="profile-optionsIcon shadow1"
              >
                <FontAwesomeIcon icon={faWallet} />
              </button>
              <div className="profile-options-title">Métodos de pago</div>
            </div>
            <div className="profile-optionContainer">
              <button
                onClick={this.triggerSupportModal}
                className="profile-optionsIcon shadow1"
              >
                <FontAwesomeIcon icon={faQuestion} />
              </button>
              <div className="profile-options-title">Obtener soporte</div>
            </div>
          </div>
          {this.state.devs.length > 0 ? (
            <div className="profile-title darkgrayColor mb-3 mt-9">
              Cantidad de lotes adquiridos:
            </div>
          ) : (
            <div style={{ height: 22 }}></div>
          )}
          {this.state.loadingDevs ? (
            <div style={{ margin: "10px 0px 14px" }}>
              {this.spinner()}
              <div style={{ marginTop: "10px" }}>Cargando lotes...</div>
            </div>
          ) : this.state.errorDevs ? (
            <div
              style={{
                color: "var(--primary-red)",
                fontSize: "16px",
                padding: "10px 20px 33px",
                textAlign: "start",
              }}
            >
              Error al traer los lotes adquiridos, inténtelo más adelante...
            </div>
          ) : this.state.devs.length > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "25px",
              }}
            >
              <span className="profile-lotesAmount redColor">
                {this.state.devs.length}
              </span>
              <span className="redColor">Lotes</span>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "25px",
              }}
            >
              <button
                onClick={() =>
                  this.state.devs.length > 0
                    ? this.selectScreen("devs")
                    : this.props.history.replace(`${process.env.PUBLIC_URL}`)
                }
                className="profile-lotesButton redBackground whiteColor shadow2"
              >
                Ver lotes disponibles
              </button>
            </div>
          )}
          <div className="profile-pagosRecurrentes">
            <div className="profile-title darkgrayColor mb-4 mt-1">
              Pagos automáticos
            </div>
            <div style={{ marginBottom: "22px" }}>
              {this.state.recurrentPayments
                ? "El servicio de “Pago Automático” se encuentra activo. Con esta funcionalidad tus pagos se procesan de forma automática antes de cada vencimiento."
                : "¡Activá el servicio de “Pago Automático” para no atrasarte con ninguna cuota! Con esta funcionalidad tus pagos se procesarán de forma automática antes de cada vencimiento."}
            </div>
            <button
              onClick={this.triggerRecurrentPayments}
              className="login-button-red shadow1"
            >
              {this.state.loadingRecurrentPayments
                ? this.spinnerW()
                : this.state.recurrentPayments
                ? "Desactivar pagos automáticos"
                : "Activar pagos automáticos"}
            </button>
            {this.state.errorRecurrentPayments && (
              <div
                style={{
                  color: "var(--primary-red)",
                  fontSize: "16px",
                  paddingTop: 12,
                }}
              >
                {this.state.errorRecurrentPayments}
              </div>
            )}
          </div>
          <div className="profile-overlineTitle mb-4">proximo vencimiento</div>
          <div className="profile-line"></div>
          {this.state.loadingDebts ? (
            <div>
              {this.spinner()}
              <div style={{ marginTop: "10px" }}>
                Cargando cuotas a pagar...
              </div>
            </div>
          ) : this.state.errorDebts ? (
            <div
              style={{
                color: "var(--primary-red)",
                fontSize: "16px",
                padding: "10px 20px",
                textAlign: "start",
              }}
            >
              Error al traer las cuotas pendientes, inténtelo más adelante...
            </div>
          ) : this.state.debts.length > 0 ? (
            <div className="profile-quote-card">
              <div>
                <div className="profile-subtitle mb-1">
                  {this.state.debts[0].parcel?.development.name}
                </div>
                <div className="profile-title mb-2">
                  {this.getDate(this.state.debts[0].due_date)}
                </div>
                <div className="profile-subtitle mb-0">
                  Lote #{this.state.debts[0].parcel?.parcel_no} - Cuota{" "}
                  {this.state.debts[0].installment_no}
                </div>
              </div>
              <div style={{ textAlign: "end" }}>
                <div className="profile-quote-price mb-2">
                  Gs.{" "}
                  {this.formatNumber(
                    Math.round(
                      parseInt(this.state.debts[0].amount) +
                        parseInt(this.state.debts[0].arrears_amount)
                    )
                  )}
                </div>
                <button
                  onClick={() => this.selectAndPay(this.state.debts[0])}
                  className="profile-status"
                >
                  Pagar
                </button>
              </div>
            </div>
          ) : (
            <div style={{ textAlign: "start", padding: "12px 20px" }}>
              ¡Bien, no tienes cuotas pendientes!
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="profile-background">
        <div className="home-header">
          <span onClick={this.goBack} className="home-back">
            <img className="home-back-arrow" src={back} alt="back"></img>
            <span className="home-back-text">Atrás</span>
          </span>
          <img className="home-header-logo" src={inmoLogo2} alt="inmo"></img>
          <div style={{ width: "63px" }}></div>
        </div>
        {this.state.loadingProfileData ? (
          <div
            style={{
              height: "calc(100vh - 75px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.spinner()}
          </div>
        ) : this.state.errorProfile ? (
          <div
            style={{
              color: "var(--primary-red)",
              fontSize: "16px",
              padding: "60px 30px",
            }}
          >
            Error al traer los datos del usuario, inténtelo más adelante...
          </div>
        ) : (
          <div>
            <div
              className={`profile-container ${
                this.state.showScreen ? "heightFixed" : ""
              }`}
            >
              <div className="profile-header">
                {this.state.profileData && (
                  <div>
                    <div className="profile-title">
                      {this.state.profileData.first_name}{" "}
                      {this.state.profileData.last_name}
                    </div>
                    <div className="profile-subtitle">
                      {this.state.profileData.email}
                    </div>
                    <div className="profile-description">
                      C.I. {this.state.profileData.gov_id}
                    </div>
                  </div>
                )}
                {this.state.profileData && (
                  <div
                    style={{
                      display: "inline-grid",
                      alignContent: "space-between",
                      marginLeft: "10px",
                    }}
                  >
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={this.goToSettings}
                      src={settingsIcon}
                      alt="settings"
                    ></img>
                    <FontAwesomeIcon
                      className="profile-icon"
                      onClick={this.logout}
                      icon={faSignOutAlt}
                    />
                  </div>
                )}
              </div>
              {this.whiteBody()}
            </div>
            <div className="profile-list-container mobile-noDisplay">
              {this.listScreenContent()}
            </div>
          </div>
        )}

        {this.state.showScreen && this.listScreen()}
        {this.state.selectedDelete && this.deleteModal()}
        {this.state.selectedSetDefault && this.setDefaultModal()}
        {this.state.showSupportModal && this.supportModal()}
        {this.state.toPayList && (
          <PayModal
            list={this.state.toPayList}
            cards={this.state.cards}
            pay={this.pay}
            close={() => this.setState({ toPayList: null })}
          />
        )}
        {this.state.showCardRegister && (
          <CardRegister
            bancardToken={this.bancardToken}
            exit={this.closeCardRegister}
          />
        )}
        {this.state.showCheckout && (
          <Checkout
            bancardToken={this.bancardToken}
            exit={this.closeCheckout}
          />
        )}
        {this.state.showZimple && (
          <Zimple bancardToken={this.bancardToken} exit={this.closeZimple} />
        )}
      </div>
    );
  }
}

export default Profile;
