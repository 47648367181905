import React, { Component } from "react";
import ReactMapGL, {
  Source,
  Layer,
  Marker,
  NavigationControl,
  LinearInterpolator,
} from "react-map-gl";
import { easeCubic } from "d3-ease";
import useSupercluster from "use-supercluster";

import secrets from "./../secrets.json";
import whatsapp from "../assets/images/whatsapp.svg";
import NESO from "../assets/images/NESO.svg";
import mapmark from "../assets/images/interface/mapmark.svg";
import close from "../assets/images/interface/close.svg";
import closeWhite from "../assets/images/interface/closeWhite.svg";
import optionsIcon from "../assets/images/interface/options.svg";
import helpIcon from "../assets/images/interface/help-circle-outline.svg";
import mapArrow from "../assets/images/mapArrow.svg";

function ClusteredMapGL(props) {
  /*let [state, setState] = React.useState({
    hoveredFeature: null,
  })*/

  const mapRef = React.useRef();
  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : null;
  const newPoints = props.markers
    .filter((m) => m.id !== props.currentLoteamientoId)
    .map((m) => ({
      // excludes current dev
      ...m,
      properties: {
        ...m.properties,
        cluster: false,
      },
    }));
  const { clusters } = useSupercluster({
    points: newPoints,
    bounds,
    zoom: props.zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const renderGeoJson = function () {
    /*if (props.currentParcels) {*/
    return (
      <div>
        <div>
          <Source type="geojson" data={props.currentParcels}>
            <Layer {...props.dataLayer} />
          </Source>
          {props.zoom >= 16.5 &&
            props.currentParcels &&
            props.currentParcels.features.map((f, index) => {
              if (f.properties.centralPoint) {
                return (
                  <Marker
                    key={`${f.properties.block}-${f.properties.parcel_no}-${index}`}
                    latitude={f.properties.centralPoint[1]}
                    longitude={f.properties.centralPoint[0]}
                  >
                    <div className="map-inMapNumber">
                      L{f.properties.parcel_no}
                    </div>
                  </Marker>
                );
              } else {
                return null;
              }
            })}
          {props.zoom >= 14.8 &&
            props.zoom < 16.5 &&
            props.blocksCentralPoints.map((bcp, index) => {
              if (bcp) {
                return (
                  <Marker
                    key={`${bcp.name}-${index}`}
                    latitude={bcp.lat / bcp.div}
                    longitude={bcp.lon / bcp.div}
                  >
                    <div className="map-inMapBlock">M{bcp.name}</div>
                  </Marker>
                );
              } else {
                return null;
              }
            })}
        </div>
        {/* else if (props.markers) {*/}
        <div>
          {clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } =
              cluster.properties;

            if (!isCluster) {
              return (
                <Marker
                  key={cluster.id}
                  latitude={latitude}
                  longitude={longitude}
                >
                  <div
                    className="map-marker"
                    onClick={() => props.setCurrentLoteamiento(cluster)}
                  >
                    <div className="map-marker-body">
                      {cluster.total_parcels}
                    </div>
                    <div className="map-marker-arrow">
                      <img src={mapArrow} alt="V"></img>
                    </div>
                  </div>
                </Marker>
              );
            } else {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  latitude={latitude}
                  longitude={longitude}
                >
                  <div className="map-cluster">{pointCount}</div>
                </Marker>
              );
            }
          })}
        </div>
      </div>
    );
    // }
  };

  return (
    <ReactMapGL {...props} ref={mapRef}>
      <div style={{ position: "absolute", right: "24px", bottom: "205px" }}>
        <NavigationControl showCompass={false}></NavigationControl>
      </div>
      <div className="map-compass">
        <NavigationControl
          showZoom={false}
          compassLabel="Reestablecer norte"
        ></NavigationControl>
      </div>
      {renderGeoJson()}
      {/*renderTooltip()*/}
    </ReactMapGL>
  );
}

class InmoMap extends Component {
  constructor(props) {
    super(props);
    this.parcelModal = this.parcelModal.bind(this);
  }

  politicStyle = "mapbox://styles/ramirosilvero/ckjww1eo715ft17pp4diawhx5";
  hybridStyle = "mapbox://styles/ramirosilvero/ckgawxpv583xb19peyjobkjaw";

  // geojson = this.props.geojson;
  state = {
    viewport: this.props.viewport,
    mapStyle: this.politicStyle,
    autoStyle: true,

    currentParcels: this.props.parcels,
    markers: this.props.markers,
    clickedFeature: null,
    clickedCoordinates: null,
    optionsScreen: false,
    legendScreen: window.innerWidth > 800,
    transparency: 20,
    dataLayer: {
      id: "data",
      type: "fill",
      paint: {
        "fill-color": {
          property: "color",
          stops: [
            [0, "rgba(119, 119, 119, 0.8)"],
            [1, "rgba(210, 45, 64, 0.8)"],
            [2, "rgba(97, 231, 63, 0.8)"],
            [3, "rgba(242, 222, 36, 0.8)"],
          ],
        },
        "fill-opacity": 1 /*Set opacity to fill AND fill outline*/,
        "fill-outline-color": "#000",
      },
    },
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateMapDimensions);
  }

  updateMapDimensions = () => {
    this.setState({
      viewport: {
        ...this.state.viewport,
        width: this.props.viewport.width,
        height: this.props.viewport.height,
      },
    });
  };

  newLocationTransition(lat, lon, animation) {
    if (animation) {
      // animated
      this.setState({
        viewport: {
          ...this.state.viewport,
          longitude: lon,
          latitude: lat,
          zoom: 14.7, // if you change this value, CHANGE the conditional value in changeViewport function
          // look for 'this.state.viewport.zoom !== current value' and change it to 'this.state.viewport.zoom !== new value', map.js line 138 aprox
          transitionDuration: 2500,
          transitionInterpolator: new LinearInterpolator(),
          transitionEasing: easeCubic,
        },
      });
    } else {
      // no animated
      this.setState({
        viewport: {
          ...this.state.viewport,
          longitude: lon,
          latitude: lat,
          zoom: 14.7, // if you change this value, CHANGE the conditional value in changeViewport function
          // look for 'this.state.viewport.zoom !== current value' and change it to 'this.state.viewport.zoom !== new value', map.js line 138 aprox
        },
      });
    }
  }

  clickMap = (event) => {
    if (
      !this.state.clickedFeature &&
      !this.state.optionsScreen &&
      event.features.length > 0 // &&
      /*(!event.features[0].properties.name ||
        event.features[0].properties.name === 'null') && // si el nombre es nulo o dice 'null' (asi viene de la api)
      (event.features[0].properties.status &&
        event.features[0].properties.status !== 'null' &&
        event.features[0].properties.status !== 'public_space'
      ) // si el estado no es nulo ni dice 'null' */
    ) {
      this.setState({
        clickedFeature: event.features[0].properties,
        clickedCoordinates: event.features[0].geometry.coordinates[0][0],
        legendScreen: false,
      });
    }
  };

  parcelModal(feature) {
    this.setState({
      clickedFeature: feature.properties,
      clickedCoordinates: feature.geometry.coordinates[0][0],
      legendScreen: false,
    });
  }

  changeViewport = (viewport) => {
    // if this.state.viewport.zoom is greater than viewport.zoom, the map is zooming out and viceversa
    // when zoom sets to less than 12...
    // como 14.7 es el zoom en el cual se termina la animacion, y dificilmente se pase directamente de 14.7 a menos de 12,
    // podemos considerar a un salto de menos 12 a 14.7 como un salto que solamente se da en una animacion, por lo cual se omite este caso para
    // ejecutar unsetLoteamiento agregando this.state.viewport.zoom !== 14.7
    if (
      this.state.currentParcels &&
      viewport.zoom < 12 &&
      this.state.viewport.zoom >= viewport.zoom &&
      this.state.viewport.zoom !== 14.7 &&
      this.state.currentParcels !== null
    ) {
      this.props.unsetLoteamiento();
    }
    // this.props.setBounds(this.mapRef.current ? this.mapRef.current.getMap().getBounds().toArray().flat() : null);
    this.setState({
      viewport,
      mapStyle: this.state.autoStyle
        ? viewport.zoom >= 12
          ? this.hybridStyle
          : this.politicStyle
        : this.state.mapStyle,
    });
  };

  swapStyle(event) {
    if (event.target.value === "auto") {
      this.setState({
        mapStyle:
          this.state.viewport.zoom >= 12 ? this.hybridStyle : this.politicStyle,
        autoStyle: true,
      });
    } else {
      this.setState({
        mapStyle: event.target.value,
        autoStyle: false,
      });
    }
  }

  unsetDpto = () => {
    this.setState({ selectedDpto: null });
  };

  unsetClickedFeature = () => {
    this.setState({
      clickedFeature: null,
      clickedCoordinates: null,
      legendScreen: window.innerWidth > 800 ? true : this.state.legendScreen,
    });
  };

  triggerOptions = () => {
    if (!this.state.clickedFeature) {
      this.setState({
        optionsScreen: !this.state.optionsScreen,
        legendScreen:
          window.innerWidth > 800
            ? this.state.optionsScreen
            : this.state.legendScreen,
      });
    }
  };

  triggerLegend = () => {
    if (!this.state.optionsScreen && !this.state.clickedFeature) {
      this.setState({ legendScreen: !this.state.legendScreen });
    }
  };

  openWhatsapp = () => {
    const lote = this.state.clickedFeature;
    let msg = `¡Hola! Quiero saber más sobre el lote ${lote.parcel_no} de la manzana ${lote.block}, del loteamiento ${this.props.name}`;
    window.open(
      `https://api.whatsapp.com/send?phone=${this.props.phone}&text=${msg}`,
      "_blank"
    );
  };

  openGoogleMaps = () => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${this.state.clickedCoordinates[1]},${this.state.clickedCoordinates[0]}`,
      "_blank"
    );
  };

  getModalTitleStyle() {
    /*if (this.state.clickedFeature.name && this.state.clickedFeature.name !== 'null') {
      return 'darkgrayBackground whiteColor'
    } else {*/
    switch (this.state.clickedFeature.status) {
      case "sold":
        return "redBackground whiteColor";
      case "available":
        return "greenBackground2";
      case "reserved":
        return "yellowBackground";
      default:
        return "darkgrayBackground whiteColor";
    }
    // }
  }

  getModalTitle() {
    // if (this.state.clickedFeature.name && this.state.clickedFeature.name !== 'null') {
    //   return 'Espacio público'
    // } else {
    switch (this.state.clickedFeature.status) {
      case "sold":
        return "Vendido";
      case "available":
        return "Disponible";
      case "reserved":
        return "Reservado";
      default:
        return "Espacio público";
    }
    // }
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  setOpacity(event) {
    this.setState({
      transparency: event.target.value,
      dataLayer: {
        ...this.state.dataLayer,
        paint: {
          ...this.state.dataLayer.paint,
          "fill-color": {
            property: "color",
            stops: [
              [0, `rgba(119, 119, 119, ${(100 - event.target.value) / 100})`],
              [1, `rgba(210, 45, 64, ${(100 - event.target.value) / 100})`],
              [2, `rgba(97, 231, 63, ${(100 - event.target.value) / 100})`],
              [3, `rgba(242, 222, 36, ${(100 - event.target.value) / 100})`],
            ],
          },
        },
      },
    });
  }

  optionsModal() {
    return (
      <div className="optionsModal">
        <div className="modal-header">
          <img height="30px" src={optionsIcon} alt="?"></img>
          <div className="optionsModal-title">Ajustes mapa</div>
          <img
            onClick={this.triggerOptions}
            className="modal-close"
            src={close}
            alt="X"
          ></img>
        </div>

        <div className="optionsModal-body">
          <div className="optionsModal-option">
            <div className="optionsModal-text" style={{ marginBottom: "25px" }}>
              Estilo de mapa
            </div>
            <select
              defaultValue={this.state.autoStyle ? "auto" : this.state.mapStyle}
              onChange={(event) => this.swapStyle(event)}
              className="home-filters-dropdown shadow2"
            >
              <option value="auto">Político</option>
              <option value={this.hybridStyle}>Satélite</option>
            </select>
          </div>
          <div className="optionsModal-option">
            <div className="optionsModal-text">Transparencia de lotes</div>
            <input
              ref={this.state.transparency}
              defaultValue={this.state.transparency}
              onChange={(event) => this.setOpacity(event)}
              className="optionsModal-range"
              type="range"
              min="0"
              max="100"
            ></input>
            <div className="optionsModal-rangeNumber">
              {this.state.transparency} %
            </div>
          </div>
        </div>
      </div>
    );
  }

  legendModal() {
    return (
      <div className="legendModal">
        <div className="modal-header">
          <div className="legendModal-title">Estado de lotes</div>
          <img
            onClick={this.triggerLegend}
            className="modal-close desktop-noDisplay"
            src={close}
            alt="X"
          ></img>
        </div>
        <div className="legendModal-body">
          <div className="legendModal-item">
            <div className="legendModal-square greenBackground"></div>
            <div className="legendModal-text">Disponible</div>
          </div>
          <div className="legendModal-item">
            <div className="legendModal-square yellowBackground"></div>
            <div className="legendModal-text">Reservado/Recuperado</div>
          </div>
          <div className="legendModal-item">
            <div className="legendModal-square redBackground"></div>
            <div className="legendModal-text">Vendido</div>
          </div>
        </div>
      </div>
    );
  }

  modal() {
    return (
      <div className="modal shadow1">
        <div className={`modal-header ${this.getModalTitleStyle()}`}>
          {this.getModalTitle() === "Espacio público" ? (
            this.state.clickedFeature.name &&
            this.state.clickedFeature.name !== "null" && (
              <div className="modal-title">
                {this.state.clickedFeature.name}
              </div>
            )
          ) : (
            <div className="modal-title">
              Lote {this.state.clickedFeature.parcel_no}
            </div>
          )}
          <div className="modal-loteState">{this.getModalTitle()}</div>
          <img
            onClick={this.unsetClickedFeature}
            className="modal-close"
            src={
              this.getModalTitle() === "Vendido" ||
              this.getModalTitle() === "Espacio público"
                ? closeWhite
                : close
            }
            alt="X"
          ></img>
        </div>
        <div className="modal-body">
          <div className="modal-data">
            <span className="weight600">Manzana:</span>
            <span>{this.state.clickedFeature.block}</span>
          </div>
          <div className="modal-data">
            <span className="weight600">Superficie:</span>
            <span>{this.state.clickedFeature.area} m²</span>
          </div>
          {this.getModalTitle() !== "Espacio público" &&
            this.state.clickedFeature.status !== "sold" && (
              <div className="modal-data">
                <span className="weight600">Cuota:</span>
                <span>
                  {this.numberWithCommas(
                    this.state.clickedFeature.monthly_payment
                  )}{" "}
                  {this.state.clickedFeature.currency}
                </span>
              </div>
            )}
          <div className="modal-data mb-0">
            <span className="weight600">Dimensiones:</span>
            <img className="brujula-noDisplay" src={NESO} alt="N E S O"></img>
            <div className="modal-data-dimensions">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <span className="weight600 mr-2">N</span>{" "}
                  {this.state.clickedFeature.side_north}m
                </div>
                <div>
                  <span className="weight600 mr-2">E</span>{" "}
                  {this.state.clickedFeature.side_east}m
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <span className="weight600 mr-2">S</span>{" "}
                  {this.state.clickedFeature.side_south}m
                </div>
                <div>
                  <span className="weight600 mr-2">O</span>{" "}
                  {this.state.clickedFeature.side_west}m
                </div>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            {this.state.clickedFeature.status === "available" &&
              (!this.state.clickedFeature.name ||
                this.state.clickedFeature.name === "null") && (
                <button
                  onClick={this.openWhatsapp}
                  className="modal-button whatsappColor shadow1"
                >
                  <img
                    style={{
                      height: "20px",
                      marginRight: "10px",
                    }}
                    src={whatsapp}
                    alt="wha"
                  ></img>
                  Contactanos
                </button>
              )}
            <button
              onClick={this.openGoogleMaps}
              className="modal-button googleMapsButton shadow1"
            >
              <img
                style={{ height: "20px", marginRight: "10px" }}
                src={mapmark}
                alt="map"
              ></img>
              Ver en Google Maps
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div style={{ position: "relative" }}>
        <ClusteredMapGL
          {...this.state.viewport}
          onViewportChange={this.changeViewport}
          mapboxApiAccessToken={secrets.mapbox_access_token}
          mapStyle={this.state.mapStyle}
          style={{
            marginLeft: this.props.left,
            marginTop: "var(--detailHeaders-height)",
          }}
          onClick={this.clickMap}
          dataLayer={this.state.dataLayer}
          currentParcels={this.state.currentParcels}
          markers={this.state.markers}
          setCurrentLoteamiento={this.props.setCurrentLoteamiento}
          renderTooltip={this.renderTooltip}
          parcelModal={this.parcelModal}
          currentLoteamientoId={this.props.currentLoteamientoId}
          blocksCentralPoints={this.props.blocksCentralPoints}
        ></ClusteredMapGL>
        {this.state.optionsScreen ? null : (
          <button
            onClick={this.triggerOptions}
            className="map-optionsButton shadow1"
          >
            Ajustes mapa
            <img
              height="15px"
              style={{ marginLeft: "5px" }}
              src={optionsIcon}
              alt=""
            ></img>
          </button>
        )}
        {/*<button className="map-whatsappButton shadow1"><img height="28px" style={{marginTop: '3px'}} src={whatsapp} alt="wha"></img></button>*/}
        {this.state.legendScreen ? null : (
          <button
            onClick={this.triggerLegend}
            className="map-legendButton shadow1"
          >
            Estado de lotes{" "}
            <img
              height="22px"
              style={{ marginLeft: "5px" }}
              src={helpIcon}
              alt="?"
            ></img>
          </button>
        )}
        {this.state.clickedFeature ? this.modal() : null}
        {this.state.optionsScreen ? this.optionsModal() : null}
        {this.state.legendScreen ? this.legendModal() : null}
      </div>
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateMapDimensions);
  }
}

export default InmoMap;
