import React from "react";
import close from "../assets/images/interface/close.svg";

const Alert = ({ message, onClose }) => {
  return (
    <div
      className="modal"
      style={{
        backgroundColor: "#ededed",
        boxShadow: "0px 4px 10px 2px rgba(0, 0, 0, 0.2)",
      }}
    >
      <div
        style={{
          padding: 20,
          borderRadius: 8,
          maxWidth: 400,
          textAlign: "center",
          color: "var(--primary-red)",
          fontSize: 20,
          fontWeight: "700",
          lineHeight: "22px",
          margin: 20,
        }}
      >
        <img
          className="modal-close"
          style={{ position: "absolute", top: 10, right: 10 }}
          onClick={onClose}
          src={close}
          alt="X"
        ></img>
        <p>{message}</p>
        <button className="login-button-red " onClick={onClose}>
          OK
        </button>
      </div>
    </div>
  );
};

export default Alert;
