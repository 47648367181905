import React from "react";
import { useState } from "react";
import "./confirmAddCard.css";
import "./home.css";
import "../loader.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import back from "../assets/images/interface/back.svg";
import inmoLogo2 from "../assets/images/inmoLogo2.png";
import { useEffect } from "react";

const TigoPaymentConfirm = (props) => {
    const [loading, setLoading] = useState(true);
    const [txResponse, setTxResponse] = useState(null);

    const goBackToProfile = () => {
        props.history.replace(`${process.env.PUBLIC_URL}/profile`);
    };

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        setTxResponse({
            transactionStatus: urlParams.get("transactionStatus"),
            merchantTransactionId: urlParams.get("merchantTransactionId"),
            mfsTransactionId: urlParams.get("mfsTransactionId"),
            transactionCode: urlParams.get("transactionCode"),
            transactionDescription: urlParams.get("transactionDescription"),
        });
        setLoading(false);
    }, []);

    const spinner = () => {
        return (
            <div className="spinner spinner-red">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
        );
    };

    return (
        <div>
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        height: "100vh",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {spinner()}
                </div>
            ) : (
                <div className="confirmAddCard">
                    <div className="home-header">
                        <span onClick={goBackToProfile} className="home-back">
                            <img
                                className="home-back-arrow"
                                src={back}
                                alt="back"
                            ></img>
                            <span className="home-back-text">Salir</span>
                        </span>
                        <img
                            className="home-header-logo"
                            src={inmoLogo2}
                            alt="inmo"
                        ></img>
                        <div style={{ width: "63px" }}></div>
                    </div>
                    <div className="confirmAddCard-container">
                        {txResponse.transactionStatus !== "success" ? (
                            <div>
                                <div className="confirmAddCard-status">
                                    <FontAwesomeIcon
                                        style={{ marginRight: "12px" }}
                                        color="var(--primary-red)"
                                        icon={faTimes}
                                    />{" "}
                                    Transacción{" "}
                                    {txResponse.transactionStatus === "fail"
                                        ? "Rechazada"
                                        : "Cancelada"}
                                </div>
                                {txResponse.transactionStatus === "fail" ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            padding: "6px 0px 17px",
                                            color: "var(--primary-dark-gray)",
                                        }}
                                    >
                                        Hubo un problema al procesar su
                                        transacción, por favor, intentalo más
                                        tarde
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            width: "100%",
                                            padding: "6px 0px 17px",
                                            color: "var(--primary-dark-gray)",
                                        }}
                                    >
                                        Usted canceló la transacción.
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                <div className="confirmAddCard-status">
                                    <FontAwesomeIcon
                                        style={{ marginRight: "12px" }}
                                        color="var(--primary-green)"
                                        icon={faCheck}
                                    />{" "}
                                    Transacción Aprobada
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                        padding: "6px 0px 17px",
                                        color: "var(--primary-dark-gray)",
                                    }}
                                >
                                    <span>ID de transacción: </span>
                                    <span style={{ fontWeight: 600 }}>
                                        {txResponse.merchantTransactionId}
                                    </span>
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                        padding: "6px 0px 17px",
                                        color: "var(--primary-dark-gray)",
                                    }}
                                >
                                    <span>Código de transacción: </span>
                                    <span style={{ fontWeight: 600 }}>
                                        {txResponse.transactionCode}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="textCentered">
                        <button
                            onClick={goBackToProfile}
                            className="confirmAddCard-button"
                        >
                            Aceptar
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TigoPaymentConfirm;
