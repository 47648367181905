import React, { Component } from 'react';
import api from '../api';
import './confirmAddCard.css';
import './home.css';
import '../loader.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

import back from '../assets/images/interface/back.svg';
import inmoLogo2 from '../assets/images/inmoLogo2.png';

/* Pago exitoso ejemplo
{
    amount: "651700.00"
    payment_id: 2
    response_description: "Transaccion aprobada"
    status: "success"
    tx_datetime: "2021-06-02T17:23:57.414261-04:00"
    tx_id: 29901
}*/

class PaymentResponse extends Component {

    // url parameters
    txId = null;

    state = {
        loading: true,
        status: '',
        response: null,
    }

    spinner() {
        return <div className="spinner spinner-red">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
        </div>
    }

    componentDidMount() {
        const parameters = this.props.location.search;

        // process to get 'tx_id' url parameter
        let from = parameters.search('tx_id'); // calculating where to cut (is for substr)
        let temp = '';
        let until = '';
        if (from > -1) {
            temp = parameters.substr(from + 6); // removing everything before '?tx_id=' (this string included)
            until = temp.search('&'); // calculating how many charts until '&' appears (is for substr)
            this.txId = until > -1 ? temp.substr(0, until) : temp;
        }

        // showing response
        api.post('v1/payments/single-buy/confirm/', { tx_id: this.txId }).then(res => {
            this.setState({
                status: res.data.status,
                loading: false,
                response: res.data
            })
        }, err => {
            this.setState({
                status: 'error',
                loading: false,
                response: null
            })
            console.log('ERROR AL REALIZAR PAGO: ', err.response ? err.response : err)
        });
    }

    goBackToProfile = () => {
        this.props.history.replace(`${process.env.PUBLIC_URL}/profile`)
    }

    getFullDate(date) {
        let months = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        return !date ? '' : `${date.substr(8, 1) === '0' ? date.substr(9, 1) : date.substr(8, 2)} de ${months[parseInt(date.substr(5, 2), 10)]} del ${date.substr(0, 4)} - ${date.substr(11, 5)} hs.`;
    }

    formatNumber(number) {
        return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '';
    }

    render() {
        return this.state.loading ?
            <div style={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                {this.spinner()}
            </div> :
            <div className="confirmAddCard">
                <div className="home-header">
                    <span onClick={this.goBackToProfile} className="home-back">
                        <img className="home-back-arrow" src={back} alt="back"></img>
                        <span className="home-back-text" >Salir</span>
                    </span>
                    <img className="home-header-logo" src={inmoLogo2} alt="inmo"></img>
                    <div style={{ width: '63px' }}></div>
                </div>
                <div className="confirmAddCard-container">
                    {this.state.status !== 'success' ?
                        <div className="confirmAddCard-status"><FontAwesomeIcon style={{ marginRight: '12px' }} color="var(--primary-red)" icon={faTimes} /> Transacción Rechazada</div> :
                        <div className="confirmAddCard-status"><FontAwesomeIcon style={{ marginRight: '12px' }} color="var(--primary-green)" icon={faCheck} /> Transacción Aprobada</div>}
                    {this.state.response ? <div>
                        {this.state.response.response_description && this.state.response.response_description !== "" && <div style={{ width: '100%', padding: '6px 0px 17px', color: 'var(--primary-dark-gray)' }}>
                            Descripción: {this.state.response.response_description}
                        </div>}
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '12px 0px', color: 'var(--primary-dark-gray)' }}>
                            <span>
                                Monto
                            </span>
                            <span style={{ fontWeight: 600 }}>
                                {this.formatNumber(Math.round(this.state.response.amount))} Gs.
                            </span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '12px 0px', color: 'var(--primary-dark-gray)' }}>
                            <span>
                                Fecha de pago
                            </span>
                            <span style={{ fontWeight: 600 }}>
                                {this.getFullDate(this.state.response.tx_datetime)}
                            </span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '12px 0px', color: 'var(--primary-dark-gray)' }}>
                            <span>
                                Número de transacción
                            </span>
                            <span style={{ fontWeight: 600 }}>
                                {this.state.response.tx_id}
                            </span>
                        </div>
                    </div> : <div>
                        <div style={{ width: '100%', padding: '6px 0px 17px', color: 'var(--primary-dark-gray)' }}>
                            Hubo un problema al procesar su transacción, por favor, intentalo más tarde
                        </div>
                    </div>}
                </div>
                <div className="textCentered"><button onClick={this.goBackToProfile} className="confirmAddCard-button">Aceptar</button></div>
            </div>
    }
}

export default PaymentResponse;