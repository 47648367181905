import React, { Component } from 'react';
import api from '../api';
import './home.css';
import './profile.css';

import back from '../assets/images/interface/back.svg';
import inmoLogo2 from '../assets/images/inmoLogo2.png';
import close from '../assets/images/interface/close.svg';
import closeW from '../assets/images/interface/closeWhite.svg';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHistory, faList } from '@fortawesome/free-solid-svg-icons';
// import { faCreditCard } from '@fortawesome/free-regular-svg-icons';


class EditProfile extends Component {
    state = {
        loading: true,
        loadingSubmit: false,
        profileData: null,
        form: {
            firstName: null,
            lastName: null,
            ci: null,
            email: null,
            phone: null,
            address: null,
            sex: 'M',
            nationality: 'P'
        },
        error: null, // popup error
        success: null // popup success
    }

    componentDidMount() {
        api.get('v1/users/me/').then(res => {
            this.setState({
                profileData: res.data,
                loading: false,
                form: {
                    firstName: res.data.first_name,
                    lastName: res.data.last_name,
                    ci: res.data.gov_id,
                    email: res.data.email,
                    phone: res.data.phone,
                    address: res.data.home_address,
                    sex: res.data.sex ? res.data.sex : 'M',
                    nationality: res.data.nationality
                }
            });
        }, () => {
            console.log('ERROR EN USERS ME');
        });
    }

    goBack = () => {
        this.props.history.goBack();
    }

    // setters
    setFirstName(event) {
        this.setState({
            form: {
                ...this.state.form,
                firstName: event.target.value === '' ? null : event.target.value
            }
        })
    }
    setLastName(event) {
        this.setState({
            form: {
                ...this.state.form,
                lastName: event.target.value === '' ? null : event.target.value
            }
        })
    }
    /*setCI (event) {
        this.setState({
            form: {
                ...this.state.form,
                ci: event.target.value === '' ? null : event.target.value
            }
        })
    }*/
    setEmail(event) {
        this.setState({
            form: {
                ...this.state.form,
                email: event.target.value === '' ? null : event.target.value
            }
        })
    }
    setPhone(event) {
        this.setState({
            form: {
                ...this.state.form,
                phone: event.target.value === '' ? null : event.target.value
            }
        })
    }
    setAddress(event) {
        this.setState({
            form: {
                ...this.state.form,
                address: event.target.value === '' ? null : event.target.value
            }
        })
    }
    setSex(event) {
        this.setState({
            form: {
                ...this.state.form,
                sex: event.target.value === '' ? null : event.target.value
            }
        })
    }
    // ####

    logout = () => {
        localStorage.removeItem('inmo_access_token');
        localStorage.removeItem('inmo_refresh_token');
        this.props.history.replace(`${process.env.PUBLIC_URL}/login`);
    }

    editProfile = () => {
        this.setState({
            loadingSubmit: true,
        });
        api.put('v1/users/me/', {
            ...this.state.profileData,
            first_name: this.state.form.firstName,
            last_name: this.state.form.lastName,
            email: this.state.form.email,
            home_address: this.state.form.address,
            gov_id: this.state.form.ci,
            phone: this.state.form.phone,
            sex: this.state.form.sex,
            nationality: 'P'
        }).then(res => {
            console.log(res);
            this.setState({
                loadingSubmit: false,
                success: 'Los cambios se han guardado exitosamente'
            })
        }, err => {
            console.log(err);
            if (err.response.status === 400) {
                this.setState({
                    error: JSON.stringify(err.response.data),
                    loadingSubmit: false
                })
            } else {
                this.setState({
                    error: 'Hubo un error desconocido al guardar sus cambios',
                    loadingSubmit: false
                })
            }
        });
    }

    closePopUp = () => {
        this.setState({
            error: null,
            success: null,
        });
    }

    spinner() {
        return <div className="spinner spinner-red">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
        </div>
    }

    popUpError() {
        return <div className="popUp shadow1">
            <div className="modal-header redBackground">
                <div className="optionsModal-title whiteColor">Error</div>
                <img onClick={this.closePopUp} className="modal-close" src={closeW} alt="X"></img>
            </div>
            <div className="popUp-body">
                <div style={{ margin: '12px 0px 35px' }}>{this.state.error}</div>
                <div style={{ textAlign: 'center' }}><button onClick={this.closePopUp} className="popUp-button">Aceptar</button></div>
            </div>
        </div>
    }

    popUpSuccess() {
        return <div className="popUp shadow1">
            <div className="modal-header">
                <div className="optionsModal-title">Guardado</div>
                <img onClick={this.closePopUp} className="modal-close" src={close} alt="X"></img>
            </div>
            <div className="popUp-body">
                <div style={{ margin: '12px 0px 35px' }}>{this.state.success}</div>
                <div style={{ textAlign: 'center' }}><button onClick={this.closePopUp} className="popUp-button redColor whiteBackground shadow1">Aceptar</button></div>
            </div>
        </div>
    }

    render() {
        return <div>
            <div className="home-header">
                <span onClick={this.goBack} className="home-back">
                    <img className="home-back-arrow" src={back} alt="back"></img>
                    <span className="home-back-text" >Atrás</span>
                </span>
                <img className="home-header-logo" src={inmoLogo2} alt="inmo"></img>
                <div style={{ width: '63px' }}></div>
            </div>
            <div className="sub-title darkgrayColor textCentered">
                Editar perfil
            </div>
            {this.state.loading ?
                <div className="profile-inputs-loading">
                    <div>
                        {this.spinner()}
                        <div style={{ marginTop: '14px' }}>Cargando datos del usuario...</div>
                    </div>
                </div> :
                <div className="profile-inputs-container">
                    <div className="profile-input-label">Tu/s nombre/s</div>
                    <input onChange={(event) => this.setFirstName(event)} defaultValue={this.state.form.firstName} className="profile-input mb-6"></input>
                    <div className="profile-input-label">Tu/s apellido/s</div>
                    <input onChange={(event) => this.setLastName(event)} defaultValue={this.state.form.lastName} className="profile-input mb-6"></input>
                    <div className="profile-input-label">Número de cédula (solo lectura)</div>
                    <input disabled={true} defaultValue={this.state.form.ci} className="profile-input mb-6"></input>
                    <div className="profile-input-label">Correo electrónico</div>
                    <input onChange={(event) => this.setEmail(event)} defaultValue={this.state.form.email} className="profile-input mb-6"></input>
                    <div className="profile-input-label">Número de teléfono</div>
                    <input onChange={(event) => this.setPhone(event)} defaultValue={this.state.form.phone} className="profile-input mb-6"></input>
                    <div className="profile-input-label">Dirección</div>
                    <input onChange={(event) => this.setAddress(event)} defaultValue={this.state.form.address} className="profile-input mb-8"></input>
                    <div className="profile-input-label">Sexo</div>
                    <select onChange={(event) => this.setSex(event)} defaultValue={this.state.form.address} className="profile-input mb-8">
                        <option value={'M'}>Masculino</option>
                        <option value={'F'}>Femenino</option>
                    </select>
                    <div className="textCentered">
                        <button onClick={this.editProfile} className="profile-button shadow1 mb-3">
                            {this.state.loadingSubmit ? this.spinner() : 'Guardar cambios'}
                        </button>
                    </div>
                </div>
            }
            <div className="textCentered">
                <button onClick={this.logout} className="lowProfileButton">
                    Cerrar sesión
                </button>
                <button onClick={() => this.props.history.push(`${process.env.PUBLIC_URL}/profile/change-password`)} className="lowProfileButton">
                    Cambiar contraseña
                </button>
            </div>
            {this.state.error && this.popUpError()}
            {this.state.success && this.popUpSuccess()}
        </div>
    }
}

export default EditProfile;
