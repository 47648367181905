import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    get: {
      'Content-Type': 'application/json'
    },
    post: {
      'Content-Type': 'application/json'
    },
    put: {
      'Content-Type': 'application/json'
    },
    patch: {
      'Content-Type': 'application/json'
    },
    common: {
      'Content-Type': 'application/json'
    },
  }
});

// Autentica a todas las consultas a la API que no sean del login mismo
api.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem('inmo_access_token');
    if ((config.url.substr(0, 8) === 'v1/users' || config.url.substr(0, 11) === 'v1/payments') && userToken !== null) {
      config.headers['Authorization'] = 'Bearer ' + userToken;
    }
    return config;
  }, (error) => {
    Promise.reject(error);
  }
);

// refresh token if necessary
api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  // const originalRequest = error.config;
  if (error.response && error.response.status === 401 && error.response.data?.detail === "Given token not valid for any token type") {

    // we check if refresh token is valid, then we can try refresh our token
    const refreshToken = localStorage.getItem('inmo_refresh_token');
    if (refreshToken) {
      // if we land were that means our refresh token is valid, we refresh token then
      return api.post('api/token/refresh/', { refresh: refreshToken }).then(res => {
        localStorage.setItem('inmo_access_token', res.data.access);
        error.config.headers['Authorization'] = `Bearer ${res.data.access}`;
        return axios(error.config);
      }, () => {
        console.log('error desconocido al revonar un token valido, intente mas adelante');
        localStorage.removeItem('inmo_access_token');
        localStorage.removeItem('inmo_refresh_token');
        window.location.replace(`${process.env.PUBLIC_URL}/login`)
        return Promise.reject(error);
      });
    } else {
      return Promise.reject(error);
    }
  } else {
    return Promise.reject(error);
  }
});

export default api;