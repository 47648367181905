import React, { Component } from 'react';
import './home.css';
import './login.css';
import '../loader.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
// import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';

import inmoLogo from '../assets/images/inmoLogo2.png';
import close from '../assets/images/interface/close.svg';
import api from '../api';

class Register extends Component {
  state = {
    checked: false, // terms and conditions
    error: null, // error on register, text to show in it
    success: null, // success on register, text to show in it
    ci: '',
    screen: 'ci', // ci, questions, form
    form: {
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      repeatedPass: '',
    },
    questions: null, // if user already exists on inmo system, it will return some questions to awnser, used to trigger modal too
    loading: false,
  };

  formatNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  triggerCheckbox = () => {
    this.setState({
      checked: !this.state.checked,
    });
  };

  goToLoginPage = () => {
    this.props.history.replace(`${process.env.PUBLIC_URL}/login`);
  };

  closePopUpError = () => {
    this.setState({
      error: null,
    });
  };

  closePopUpSuccess = () => {
    this.setState({
      success: null,
    });
    this.goToLoginPage();
  };

  setCi(event) {
    this.setState({
      ci: event.target.value,
    });
  }

  setUsername(event) {
    this.setState({
      form: {
        ...this.state.form,
        username: event.target.value,
      },
    });
  }

  setFistname(event) {
    this.setState({
      form: {
        ...this.state.form,
        firstName: event.target.value,
      },
    });
  }

  setLastname(event) {
    this.setState({
      form: {
        ...this.state.form,
        lastName: event.target.value,
      },
    });
  }

  setEmail(event) {
    this.setState({
      form: {
        ...this.state.form,
        email: event.target.value,
      },
    });
  }

  setPhone(event) {
    this.setState({
      form: {
        ...this.state.form,
        phone: event.target.value,
      },
    });
  }

  setPassword(event) {
    this.setState({
      form: {
        ...this.state.form,
        password: event.target.value,
      },
    });
  }

  setRepeatedPass(event) {
    this.setState({
      form: {
        ...this.state.form,
        repeatedPass: event.target.value,
      },
    });
  }

  setParcelAnswer(event) {
    this.setState({
      answers: {
        ...this.state.answers,
        parcel:
          event.target.value !== 'Selecciona un lote'
            ? event.target.value
            : null,
      },
    });
  }

  setPaymentAnswer(event) {
    this.setState({
      answers: {
        ...this.state.answers,
        payment:
          event.target.value !== 'Selecciona un pago'
            ? event.target.value
            : null,
      },
    });
  }

  disabledSubmit() {
    const form = this.state.form;
    const screen = this.state.screen;
    switch (screen) {
      case 'ci':
        if (this.state.ci && this.state.ci !== '') {
          return false;
        }
        return true;
      case 'questions':
        const answers = this.state.answers;
        if (answers.payment && answers.parcel) {
          return false;
        }
        return true;
      case 'form':
        if (this.state.questions) {
          if (
            this.state.checked &&
            form.email !== '' &&
            form.username !== '' &&
            form.password !== '' &&
            form.repeatedPass !== '' &&
            form.password === form.repeatedPass
          ) {
            return false;
          }
          return true;
        } else {
          if (
            this.state.checked &&
            form.username !== '' &&
            form.phone !== '' &&
            form.firstName !== '' &&
            form.lastName !== '' &&
            form.email !== '' &&
            form.password !== '' &&
            form.repeatedPass !== '' &&
            form.password === form.repeatedPass
          ) {
            return false;
          }
          return true;
        }

      default:
        return false;
    }
  }

  register = () => {
    let body = {};
    this.setState({
      loading: true,
    });
    switch (this.state.screen) {
      case 'ci':
        api
          .post('v1/users/register/check-gov-id/', { gov_id: this.state.ci })
          .then(
            (res) => {
              if (res.data.payment_choices) {
                // user with given CI exists, getting questions
                this.setState({
                  screen: 'questions',
                  questions: res.data, // parcel_choices and payment_choices
                  answers: {
                    parcel: '',
                    payment: '',
                  },
                  loading: false,
                });
              } else {
                // user with given CI doesnt exist, new account
                this.setState({
                  screen: 'form',
                  loading: false,
                });
              }
            },
            (error) => {
              this.setState({
                loading: false,
                error:
                  'Hubo un problema al registrar la cédula de identidad ingresada, por favor inténtalo más adelante',
              });
            }
          );
        break;
      case 'questions':
        body = {
          gov_id: this.state.ci,
          payment: JSON.parse(this.state.answers.payment),
          parcel: JSON.parse(this.state.answers.parcel),
        };
        api.post('v1/users/register/check-answers/', body).then(
          (res) => {
            // go to questions if i get them
            this.setState({
              screen: 'form',
              key: res.data.key,
              loading: false,
            });
          },
          (error) => {
            let text = '';
            if (error.response && error.response.status === 400) {
              text = 'Respuestas incorrectas';
            } else {
              text =
                'Hubo un error al comprobar sus respuestas, por favor inténtalo más adelante';
            }
            this.setState({
              loading: false,
              error: text,
            });
          }
        );
        break;
      case 'form':
        const form = this.state.form;
        body = {
          username: form.username,
          password: form.password,
          email: form.email
        };
        if (this.state.questions) {
          body = {
            ...body,
            key: this.state.key,
          };
        } else {
          body = {
            ...body,
            username: form.username,
            phone: form.phone,
            gov_id: this.state.ci,
            first_name: form.firstName,
            last_name: form.lastName
          };
        }
        api.post('v1/users/register/', body).then(
          (res) => {
            this.setState({
              loading: false,
              success: '¡Usuario creado exitosamente!',
            });
          },
          (error) => {
            let text = ''
            if (error.response.status === 400) {
              for (let d of error.response.data.non_field_errors) {
                text = text + `${d} `
              }
            } else {
              text = `Error al registrar el usuario, por favor, inténtalo más adelante: ${JSON.stringify(error.response.data)}`
            }
            this.setState({
              loading: false,
              error: text,
            });
          }
        );
        break;
      default:
        console.log('error on register');
    }
  };

  spinner() {
    return (
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    );
  }

  checkbox() {
    return (
      <div
        className={`login-checkbox${this.state.checked ? ' checkbox-marked' : ''
          }`}
      >
        {this.state.checked ? (
          <FontAwesomeIcon icon={faCheck} color="white"></FontAwesomeIcon>
        ) : null}
      </div>
    );
  }

  popUpError() {
    return (
      <div className="popUp shadow1">
        <div className="modal-header redBackground">
          <div className="optionsModal-title whiteColor">Error</div>
          <img
            onClick={this.closePopUpError}
            className="modal-close"
            src={close}
            alt="X"
          ></img>
        </div>
        <div className="popUp-body">
          <div style={{ margin: '0px 0px 25px' }}>{this.state.error}</div>
          <div style={{ textAlign: 'center' }}>
            <button onClick={this.closePopUpError} className="popUp-button">
              Aceptar
            </button>
          </div>
        </div>
      </div>
    );
  }

  popUpSuccess() {
    return (
      <div className="popUp shadow1">
        <div className="modal-header">
          <div className="optionsModal-title">Registro completado</div>
          <div></div>
        </div>
        <div className="popUp-body">
          <div style={{ margin: '0px 0px 25px' }}>{this.state.success}</div>
          <div style={{ textAlign: 'center' }}>
            <button onClick={this.closePopUpSuccess} className="popUp-button">
              Ingresar
            </button>
          </div>
        </div>
      </div>
    );
  }

  questions() {
    return (
      <div className="popUp shadow1">
        <div className="modal-header">
          <div className="optionsModal-title">Responde algunas preguntas</div>
          {/*<img onClick={this.closePopUp} className="modal-close" src={close} alt="X"></img>*/}
        </div>
        <div className="popUp-body"></div>
      </div>
    );
  }

  whiteBody() {
    switch (this.state.screen) {
      case 'form':
        return (
          <div className="white-body">
            <img
              height="85px"
              style={{ marginBottom: '20px' }}
              src={inmoLogo}
              alt="inmo"
            ></img>
            <div className="login-title">Ingresa tus datos</div>
            <input
              onChange={(event) => this.setUsername(event)}
              value={this.state.form.username}
              className="login-input mb-4"
              placeholder="Nombre de Usuario"
            />
            {!this.state.questions && (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <input
                  onChange={(event) => this.setFistname(event)}
                  value={this.state.form.firstName}
                  className="login-input-halfWidth mb-4"
                  placeholder="Nombre/s"
                />
                <input
                  onChange={(event) => this.setLastname(event)}
                  value={this.state.form.lastName}
                  className="login-input-halfWidth mb-4"
                  placeholder="Apellido/s"
                />
              </div>
            )}
            <input
              onChange={(event) => this.setEmail(event)}
              value={this.state.form.email}
              className="login-input mb-4"
              placeholder="Correo Electrónico"
            />
            {!this.state.questions && (
              <input
                onChange={(event) => this.setPhone(event)}
                value={this.state.form.phone}
                className="login-input mb-4"
                placeholder="Teléfono"
              />
            )}
            <input
              onChange={(event) => this.setPassword(event)}
              value={this.state.form.password}
              type="password"
              className="login-input mb-4"
              placeholder="Contraseña"
            />
            <input
              onChange={(event) => this.setRepeatedPass(event)}
              value={this.state.form.repeatedPass}
              type="password"
              className="login-input mb-4"
              placeholder="Repetir Contraseña"
            />
            {this.state.form.password &&
              this.state.form.repeatedPass &&
              this.state.form.password !== this.state.form.repeatedPass && (
                <div
                  style={{
                    marginBottom: 15,
                    color: 'var(--primary-red)',
                    fontSize: 14,
                    textAlign: 'start',
                  }}
                >
                  Las contraseñas no coinciden
                </div>
              )}
            <div
              className="login-checkbox-container mb-4"
              onClick={this.triggerCheckbox}
            >
              {this.checkbox()}
              Acepto los Términos y Condiciones
            </div>
            <button
              onClick={this.register}
              disabled={this.disabledSubmit()}
              className="login-button-red mb-4"
            >
              {this.state.loading ? this.spinner() : 'Registrarme'}
            </button>
            <div onClick={this.goToLoginPage} className="login-link">
              ¿Ya tienes cuenta? Ingresa aquí
            </div>
          </div>
        );
      case 'questions':
        return (
          <div className="white-body">
            <img
              height="85px"
              style={{ marginBottom: '20px' }}
              src={inmoLogo}
              alt="inmo"
            ></img>
            <div className="login-title">Preguntas de seguridad</div>
            <div style={{ marginBottom: 33 }} className="login-description">
              Responde las preguntas de selección múltiple.
            </div>
            <div className="login-label">
              ¿Cuál de estos pagos realizados te pertenece?
            </div>
            <select
              value={this.state.answers.payment}
              onChange={(event) => this.setPaymentAnswer(event)}
              className="login-select shadow2"
            >
              <option value={null}>Selecciona un pago</option>
              {this.state.questions.payment_choices.map((pm) => (
                <option value={JSON.stringify(pm)} key={JSON.stringify(pm)}>
                  Fecha {pm.date.substr(8, 2)}/{pm.date.substr(5, 2)}/
                  {pm.date.substr(0, 4)} - Cuota {pm.installment_no} - Gs.{' '}
                  {this.formatNumber(parseInt(pm.amount))}
                </option>
              ))}
            </select>
            <div className="login-label">
              ¿Cuál de estos lotes te pertenece?
            </div>
            <select
              value={this.state.answers.parcel}
              onChange={(event) => this.setParcelAnswer(event)}
              className="login-select shadow2"
            >
              <option value={null}>Selecciona un lote</option>
              {this.state.questions.parcel_choices.map((pc) => (
                <option value={JSON.stringify(pc)} key={pc.id}>
                  {pc.development} - Lote {pc.parcel_no}, Manzana {pc.block}
                </option>
              ))}
            </select>
            <button
              onClick={this.register}
              disabled={this.disabledSubmit()}
              className="login-button-red"
            >
              {this.state.loading ? this.spinner() : 'Enviar'}
            </button>
          </div>
        );
      case 'ci':
        return (
          <div className="white-body">
            <img
              height="85px"
              style={{ marginBottom: '20px' }}
              src={inmoLogo}
              alt="inmo"
            ></img>
            <div className="login-title">Registra tu cuenta</div>
            <input
              onChange={(event) => this.setCi(event)}
              value={this.state.ci}
              name="input-ci"
              className="login-input mb-6"
              placeholder="Cédula de Identidad"
            ></input>
            <button
              onClick={this.register}
              disabled={this.disabledSubmit()}
              className="login-button-red mb-6"
            >
              {this.state.loading ? this.spinner() : 'Siguiente'}
            </button>
            <div onClick={this.goToLoginPage} className="login-link">
              ¿Ya tienes cuenta? Ingresa aquí
            </div>
          </div>
        );
      default:
        return <div className="white-body"></div>;
    }
  }

  render() {
    return (
      <div className="home-background-image">
        <div className="home-background-color">
          {this.whiteBody()}
        </div>
        {this.state.error ? this.popUpError() : null}
        {this.state.success ? this.popUpSuccess() : null}
      </div>
    );
  }
}

export default Register;
