import React, { Component } from 'react';
import api from '../api';
import './home.css';
import './login.css';
import '../loader.css';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';

import inmoLogo from '../assets/images/inmoLogo2.png';
import back from '../assets/images/interface/back.svg';
import close from '../assets/images/interface/close.svg';
import closeW from '../assets/images/interface/closeWhite.svg';

class Login extends Component {
    state = {
        forgetPassScreen: false,
        loadingSubmit: false,
        error: null, // popup error
        success: null, // popup success
    }

    username = React.createRef();
    password = React.createRef();
    email = React.createRef();

    componentDidMount() {
        if (localStorage.getItem('inmo_access_token')) {
            this.props.history.replace(`${process.env.PUBLIC_URL}`)
        }
    }

    triggerForgetPassScreen = () => {
        this.setState({
            forgetPassScreen: !this.state.forgetPassScreen
        });
    }

    closePopUp = () => {
        this.setState({
            error: null,
            success: null,
        });
    }

    onKeyDown = (event) => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            if (this.state.forgetPassScreen) {
                this.sendMail();
            } else {
                this.login();
            }
        }
    }

    sendMail = () => {
        this.setState({
            loadingSubmit: true
        })
        api.post('v1/users/password-reset/', { email: this.email.current.value }).then(res => {
            this.setState({
                success: "Hemos enviado un mensaje a su correo electrónico, por favor revise y siga las instrucciones",
                error: null,
                loadingSubmit: false
            });
        }, err => {
            if (err.response && err.response.status === 400) {
                this.setState({
                    error: "No hemos encontrado una cuenta relacionada a este correo",
                    success: null,
                    loadingSubmit: false
                });
            } else {
                this.setState({
                    error: "Error desconocido, inténtelo más adelante",
                    success: null
                });
            }
        })
    }

    goToRegisterPage = () => {
        this.props.history.replace(`${process.env.PUBLIC_URL}/register/`);
    }

    login = () => {
        if (this.username.current.value && this.password.current.value) {
            this.setState({ loadingSubmit: true });
            api.post('api/token/', {
                username: this.username.current.value,
                password: this.password.current.value
            }).then(res => {
                localStorage.setItem('inmo_access_token', res.data.access);
                localStorage.setItem('inmo_refresh_token', res.data.refresh);
                this.setState({ loadingSubmit: false });
                this.props.history.push(`${process.env.PUBLIC_URL}/profile`);
            }, err => {
                if (err.response && err.response.status === 401) {
                    this.setState({
                        error: 'El usuario y/o la contraseña ingresados son incorrectos'
                    });
                } else {
                    this.setState({
                        error: 'Error desconocido, intente de nuevo más adelante'
                    });
                }
                this.setState({ loadingSubmit: false });
            });
        } else {
            this.setState({ error: 'Completa todos los campos' })
        }
    }

    spinner() {
        return <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
        </div>
    }

    popUpError() {
        return <div className="popUp shadow1">
            <div className="modal-header redBackground">
                <div className="optionsModal-title whiteColor">Error</div>
                <img onClick={this.closePopUp} className="modal-close" src={closeW} alt="X"></img>
            </div>
            <div className="popUp-body">
                <div style={{ margin: '12px 0px 35px' }}>{this.state.error}</div>
                <div style={{ textAlign: 'center' }}><button onClick={this.closePopUp} className="popUp-button">Aceptar</button></div>
            </div>

        </div>
    }

    popUpSuccess() {
        return <div className="popUp shadow1">
            <div className="modal-header">
                <div className="optionsModal-title">Correo enviado</div>
                <img onClick={this.closePopUp} className="modal-close" src={close} alt="X"></img>
            </div>
            <div className="popUp-body">
                <div style={{ margin: '12px 0px 35px' }}>{this.state.success}</div>
                <div style={{ textAlign: 'center' }}><button onClick={this.closePopUp} className="popUp-button redColor whiteBackground shadow1">Aceptar</button></div>
            </div>
        </div>
    }

    whiteBody() {
        if (this.state.forgetPassScreen) {
            return <div className="white-body">
                <div onClick={this.triggerForgetPassScreen} className="login-back">
                    <img className="home-back-arrow" src={back} alt="back"></img>
                    <span className="home-back-text">Atrás</span>
                </div>
                <img height="85px" style={{ marginBottom: '20px' }} src={inmoLogo} alt="inmo"></img>
                <div className="login-title">¿Has olvidado tu contraseña?</div>
                <div className="login-description mb-4">
                    Te enviaremos un Link de restauración a tu correo electrónico.
                </div>
                <input ref={this.email} onKeyDown={this.onKeyDown} className="login-input mb-8" placeholder="Correo Electrónico"></input>
                <button onClick={this.sendMail} className="login-button-red button-smallPadding mb-4">
                    {this.state.loadingSubmit ? this.spinner() : 'Enviar correo de restauración'}
                </button>
            </div>
        } else {
            return <div className="white-body">
                <div onClick={() => this.props.history.goBack()} className="login-back">
                    <img className="home-back-arrow" src={back} alt="back"></img>
                    <span className="home-back-text">Atrás</span>
                </div>
                <img height="85px" style={{ marginBottom: '20px' }} src={inmoLogo} alt="inmo"></img>
                <div className="login-title">Ingresa a tu cuenta</div>
                {/*<button className="login-social-button shadow1"><FontAwesomeIcon style={{marginRight: '11px'}} color="#d22c3f" icon={faGoogle}/>Google</button>
                <button className="login-social-button shadow1 mb-4"><FontAwesomeIcon style={{marginRight: '11px'}} color="#d22c3f" icon={faFacebook}/>Facebook</button>*/}
                <input onKeyDown={this.onKeyDown} ref={this.username} className="login-input mb-4" placeholder="Nombre de usuario"></input>
                <input onKeyDown={this.onKeyDown} ref={this.password} type="password" className="login-input mb-4" placeholder="Contraseña"></input>
                <div onClick={this.triggerForgetPassScreen} className="login-getPassword-link mb-4">Olvidé mi contraseña</div>
                <button onClick={this.login} className="login-button-red mb-8">
                    {this.state.loadingSubmit ?
                        this.spinner() : 'Ingresar'}
                </button>
                <div onClick={this.goToRegisterPage} className="login-link">¿No tienes cuenta? Registrate</div>
            </div>
        }
    }

    render() {
        return <div className="home-background-image">
            <div className="home-background-color">
                {this.whiteBody()}
                <div className="home-politica">Política de Privacidad</div>
            </div>
            {this.state.error ? this.popUpError() : null}
            {this.state.success ? this.popUpSuccess() : null}
        </div>
    }
}

export default Login;