import React, { Component } from "react";
import Map from "../components/map";
import "./home.css";
import api from "../api";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

// import useSupercluster from 'use-supercluster';
// import InputRange from 'react-input-range';

import inmoLogo from "../assets/images/inmoLogo.png";
import inmoLogo2 from "../assets/images/inmoLogo2.png";
import search from "../assets/images/interface/search.svg";
import back from "../assets/images/interface/back.svg";
import close from "../assets/images/interface/close.svg";
import profileIcon from "../assets/images/interface/person-circle-outline.svg";
import profileIconW from "../assets/images/interface/person-circle-outline-white.svg";

const sortParcels = function (array) {
  return array.sort(function (a, b) {
    if (a.properties.name > b.properties.name) {
      return 1;
    }
    if (a.properties.name < b.properties.name) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });
};

const sortDistricts = function (array) {
  return array.sort(function (a, b) {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });
};

class Home extends Component {
  map = React.createRef();
  constructor(props) {
    super(props);
    // this.map = React.createRef();
    this.setCurrentLoteamiento = this.setCurrentLoteamiento.bind(this);
    this.unsetLoteamiento = this.unsetLoteamiento.bind(this);
  }

  distritos = [];
  departamentos = [];

  // filtersResults son los loteamientos a mostrar sin clusterizar y mapList son los mismos loteamientos ya clusterizados
  state = {
    loteamientos: null, // TODOS los loteamientos
    filtersResults: null, // LISTADO de loteamientos, resultado de los filtros, null si no se deben mostrar
    mapList: null, // loteamientos clusterizados para mostrar EN EL MAPA
    selectedDpto: null, // input departamento
    selectedDist: null, // input ciudad
    currentString: "", // input busqueda
    maxPrice: 5000000, // input precio
    selectedLoteamiento: null, // muestra un loteamiento al detalle, se muestra los lotes en vez del punto del loteamiento en el mapa
    filtersScreen: false, // mostrar filtros
    searchSelected: "devs", // pestaña de busqueda en el inicio (devs, dists) para loteamientos y distritos respectivamente
    searchDevelopments: [], // Loteamientos mostrados en la pantalla de inicio (resultado de busqueda)
    searchDistricts: [], // Loteamientos mostrados en la pantalla de inicio (resultado de busqueda)
    blocksCentralPoints: [], // Puntos centrales de cada manzana, sirve para mostrar el numero de manzana en cada una de ellas en el mapa
    lastUpdate: "", // Fecha de la ultima vez que se han actualizado los datos
  };

  viewport = {
    width: "calc(100% - var(--filters-width))",
    height: "calc(100vh - var(--header-height) - var(--detailHeaders-height))",
    latitude: -25.43942302360755,
    longitude: -56.46367554480092,
    zoom: 14.7, // if you change this value, CHANGE the conditional value in changeViewport function
    // look for 'this.state.viewport.zoom !== current value' and change it to 'this.state.viewport.zoom !== new value', map.js line 138 aprox
    bearing: 0,
    pitch: 0,
  };

  componentDidMount() {
    api.get("v1/development-groups/").then((res) => {
      const results = sortParcels(res.data.features);
      this.setState({
        loteamientos: results, // constant
        searchDevelopments: results,
      });
    });
    api.get("v1/districts/").then((res) => {
      this.distritos = sortDistricts(res.data); // constant
      this.setState({
        searchDistricts: this.distritos,
      });
      // obteniendo los departamentos con distritos cargados
      let flag = true;
      for (let d of this.distritos) {
        flag = true;
        for (let dep of this.departamentos) {
          if (d.department.id === dep.id) {
            flag = false;
            break;
          }
        }
        if (flag) this.departamentos.push(d.department);
      }
    });
    api.get("v1/settings").then((res) => {
      const months = [
        "",
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      const utcDate = res.data.last_sync;
      this.setState({
        lastUpdate: `${utcDate.substr(8, 2)} de ${
          months[+utcDate.substr(5, 2)]
        } del ${utcDate.substr(0, 4)}, ${utcDate.substr(11, 5)} hs.`,
      });
    });
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  search = (event) => {
    if (event.target.value) {
      api.get(`v1/search/?q=${event.target.value}`).then((res) => {
        this.setState({
          searchDevelopments: sortParcels(res.data.developments.features),
          searchDistricts: sortDistricts(res.data.districts),
        });
      });
    } else {
      this.setState({
        searchDevelopments: this.state.loteamientos,
        searchDistricts: this.distritos,
      });
    }
  };

  setSearchSelected(sel) {
    this.setState({
      searchSelected: sel,
    });
  }

  setDistritoFromHome(distID) {
    this.viewport.zoom = 11;
    api.get(`v1/development-groups/?district=${distID}`).then((res) => {
      if (res.data.features.length > 0) {
        this.viewport.latitude = res.data.features[0].geometry.coordinates[1];
        this.viewport.longitude = res.data.features[0].geometry.coordinates[0];
      }
      const parcels = sortParcels(res.data.features);
      this.setState({
        filtersResults: parcels,
        mapList: parcels,
        filtersScreen: true,
        searchSelected: "devs",
        searchDevelopments: this.state.loteamientos, // resets search state
        searchDistricts: this.distritos, // resets search state
      });
      this.map.current.setState({
        markers: parcels,
      });
    });
  }

  triggerFilters = () => {
    if (
      !this.map.current.state.legendScreen &&
      !this.map.current.state.optionsScreen &&
      !this.map.current.state.clickedFeature
    ) {
      this.setState({
        filtersScreen: !this.state.filtersScreen,
      });
    }
  };

  goToProfile = () => {
    this.props.history.push(`${process.env.PUBLIC_URL}/profile`);
  };

  setDpto(event) {
    this.setState({
      selectedDpto:
        event.target.value === null ? null : parseInt(event.target.value),
      selectedDist: null,
    });
  }

  setDist(event) {
    this.setState({
      selectedDist:
        event.target.value === null ? null : parseInt(event.target.value),
    });
  }

  setLoteamiento(grupoId) {
    // Realiza una solicitud a la API para obtener detalles del grupo de desarrollo
    api.get(`v1/development-groups/${grupoId}`).then((res) => {
      let group = res.data;
      let developments = group.properties.developments.features; // Obtén la lista de desarrollos dentro del grupo
      let blocks = [];
      let arrayIndexStartAt = 0;
      this.viewport.latitude = group.geometry.coordinates[1];
      this.viewport.longitude = group.geometry.coordinates[0];
      this.viewport.zoom = 14.7;

      // Itera a través de los desarrollos en el grupo
      developments.forEach((development) => {
        let maxArrayIndex = 0;
        // Obtén información sobre el desarrollo actual
        //let developmentName = development.properties.name;
        development.properties.parcels.features.map((f) => {
          let divisor = 0;
          let lon = 0;
          let lat = 0;
          let lastValues = [0, 0];
          if (f.geometry) {
            for (let c of f.geometry.coordinates[0]) {
              lon = lon + c[0];
              lat = lat + c[1];
              lastValues = [c[0], c[1]];
              divisor = divisor + 1;
            }
            lon = (lon - lastValues[0]) / (divisor - 1);
            lat = (lat - lastValues[1]) / (divisor - 1);
            let arrayIndex = f.properties.block;
            if (parseInt(arrayIndex)) {
              arrayIndex = parseInt(arrayIndex);
            } else {
              arrayIndex = arrayIndex.toLowerCase().charCodeAt(0) - 96;
            }
            maxArrayIndex =
              maxArrayIndex > arrayIndex ? maxArrayIndex : arrayIndex;
            arrayIndex = arrayIndex + arrayIndexStartAt;
            if (blocks[arrayIndex]) {
              blocks[arrayIndex] = {
                lon: blocks[arrayIndex].lon + parseFloat(lon),
                lat: blocks[arrayIndex].lat + parseFloat(lat),
                div: blocks[arrayIndex].div + 1,
                name: f.properties.block,
              };
            } else {
              blocks[arrayIndex] = {
                lon: parseFloat(lon),
                lat: parseFloat(lat),
                div: 1,
                name: f.properties.block,
              };
            }
          }
          return (f.properties = {
            ...f.properties,
            centralPoint: f.geometry ? [lon, lat] : null,
            color: this.getColor(f.properties),
          });
        });
        arrayIndexStartAt = arrayIndexStartAt + maxArrayIndex + 1;
      });

      // Actualiza el estado para mostrar el grupo y sus desarrollos
      this.setState({
        selectedLoteamiento: group,
        filtersResults: this.state.loteamientos,
        mapList: this.state.loteamientos,
        blocksCentralPoints: blocks,
      });
      //console.log(blocks);
    });
  }

  setCurrentLoteamiento(d) {
    if (
      !this.state.selectedLoteamiento ||
      this.state.selectedLoteamiento.id !== d.id
    ) {
      // Realiza una solicitud para obtener detalles del grupo de desarrollo que contiene este desarrollo
      api.get(`v1/development-groups/${d.id}`).then((res) => {
        let group = res.data;
        let developments = group.properties.developments.features;
        let blocks = [];
        let arrayIndexStartAt = 0;
        /*this.viewport.latitude = group.geometry.coordinates[1];
        this.viewport.longitude = group.geometry.coordinates[0];
        this.viewport.zoom = 14.7;*/

        // Itera a través de los desarrollos en el grupo
        developments.forEach((development) => {
          let maxArrayIndex = 0;
          // Obtén información sobre el desarrollo actual
          let developmentName = development.properties.name;

          development.properties.parcels.features.map((f) => {
            let divisor = 0;
            let lon = 0;
            let lat = 0;
            let lastValues = [0, 0];
            if (f.geometry) {
              for (let c of f.geometry.coordinates[0]) {
                lon = lon + c[0];
                lat = lat + c[1];
                lastValues = [c[0], c[1]];
                divisor = divisor + 1;
              }
              lon = (lon - lastValues[0]) / (divisor - 1);
              lat = (lat - lastValues[1]) / (divisor - 1);
              let arrayIndex = f.properties.block;
              if (parseInt(arrayIndex)) {
                arrayIndex = parseInt(arrayIndex);
              } else {
                arrayIndex = arrayIndex.toLowerCase().charCodeAt(0) - 96;
              }
              maxArrayIndex =
                maxArrayIndex > arrayIndex ? maxArrayIndex : arrayIndex;
              arrayIndex = arrayIndex + arrayIndexStartAt;
              if (blocks[arrayIndex]) {
                blocks[arrayIndex] = {
                  lon: blocks[arrayIndex].lon + parseFloat(lon),
                  lat: blocks[arrayIndex].lat + parseFloat(lat),
                  div: blocks[arrayIndex].div + 1,
                  name: f.properties.block,
                };
              } else {
                blocks[arrayIndex] = {
                  lon: parseFloat(lon),
                  lat: parseFloat(lat),
                  div: 1,
                  name: f.properties.block,
                };
              }
            }
            return (f.properties = {
              ...f.properties,
              centralPoint: f.geometry ? [lon, lat] : null,
              color: this.getColor(f.properties),
              developmentName: developmentName,
            });
          });
          arrayIndexStartAt = arrayIndexStartAt + maxArrayIndex + 1;
        });

        // Cuando no hay un loteamiento seleccionado anteriormente, hay un error en el dimensionamiento del mapa.
        // Por esto, actualmente tengo que desactivar la animacion en este caso

        // Actualiza el estado para mostrar el grupo y sus desarrollos
        this.setState({
          selectedLoteamiento: group,
          blocksCentralPoints: blocks,
        });
        this.map.current.setState({
          currentParcels: {
            type: "FeatureCollection",
            features: this.state.selectedLoteamiento
              ? [].concat(
                  ...this.state.selectedLoteamiento.properties.developments.features.map(
                    (f) => f.properties.parcels.features || []
                  )
                )
              : [],
          },
          // blocksCentralPoints: blocks,
          viewport: {
            ...this.map.current.state.viewport,
          },
        });

        this.fly(d.geometry.coordinates[1], d.geometry.coordinates[0], true);
      });
    }
  }

  unsetLoteamiento = () => {
    this.setState({
      selectedLoteamiento: null,
      blocksCentralPoints: [],
    });
    if (this.map.current) {
      this.map.current.setState({
        currentParcels: null,
        // blocksCentralPoints: []
      });
    }
  };

  setString(event) {
    this.setState({
      currentString: event.target.value,
    });
  }

  getColor(parcelProperties) {
    /*if (parcelProperties.name !== null) {
            return 0;
        } else {*/
    switch (parcelProperties.status) {
      case "public_space":
        return 0;
      case "sold":
        return 1;
      case "available":
        return 2;
      case "reserved":
        return 3;
      default:
        return 0;
    }
    // }
  }

  clearFiltersOrGoToFilters() {
    if (this.state.filtersResults) {
      // go back to filters
      this.setState({
        filtersResults: null,
      });
    } else {
      // clear filters
      this.setState({
        currentString: "",
        selectedDist: null,
        selectedDpto: null,
        maxPrice: 5000000,
      });
    }
  }

  applyFilters() {
    api
      .get(
        `v1/development-groups/?max_price=${this.state.maxPrice}${
          this.state.selectedDist ? "&district=" + this.state.selectedDist : ""
        }${
          this.state.selectedDpto
            ? "&district__department=" + this.state.selectedDpto
            : ""
        }`
      )
      .then((res) => {
        const results = sortParcels(
          res.data.features.filter(
            (r) =>
              r.properties.name
                .toLowerCase()
                .indexOf(this.state.currentString.toLowerCase()) > -1
          )
        );
        this.setState({
          filtersResults: results,
          mapList: results,
        });
        this.map.current.setState({
          markers: results,
        });
      });
  }

  goBack = () => {
    this.setState({
      selectedLoteamiento: null,
      filtersResults: null,
      mapList: null,
    });
  };

  setMaxPrice(event) {
    this.setState({
      maxPrice: event,
    });
  }

  fly(lat, lon, animation) {
    this.setState({ filtersScreen: false });
    this.map.current.newLocationTransition(lat, lon, animation);
  }

  filtersBody() {
    return (
      <div className="home-filters-body">
        <input
          value={this.state.currentString}
          onChange={(event) => this.setString(event)}
          className="home-search-filters mobile-noDisplay shadow1"
          type="text"
          placeholder="BUSCAR"
        ></input>
        <p className="home-input-description mobile-noDisplay darkgrayColor textCentered mb-4">
          Escribe el nombre de la ciudad o la fracción
        </p>
        <select
          value={parseInt(this.state.selectedDpto)}
          onChange={(event) => this.setDpto(event)}
          className="home-filters-dropdown shadow2"
        >
          <option value={null}>Departamento</option>
          {this.departamentos.map((d) => (
            <option value={d.id} key={d.id}>
              {d.name}
            </option>
          ))}
        </select>
        {this.state.selectedDpto ? (
          <select
            value={parseInt(this.state.selectedDist)}
            onChange={(event) => this.setDist(event)}
            className="home-filters-dropdown shadow2"
          >
            <option value={null}>Ciudad</option>
            {this.distritos
              .filter((d) => +d.department.id === +this.state.selectedDpto)
              .map((d) => (
                <option value={d.id} key={d.id}>
                  {d.name}
                </option>
              ))}
          </select>
        ) : null}
        <div className="home-filters-title" style={{ marginTop: "16px" }}>
          Monto máximo de cuota
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "0px 10px",
            boxSizing: "border-box",
          }}
        >
          <span>0 Gs.</span>
          <span>5.000.000 Gs.</span>
        </div>
        <InputRange
          maxValue={5000000}
          minValue={0}
          step={50000}
          value={this.state.maxPrice}
          onChange={(event) => this.setMaxPrice(event)}
          formatLabel={null}
        />
        <div
          style={{
            textAlign: "end",
            width: "100%",
            padding: "0px 10px",
            boxSizing: "border-box",
          }}
        >
          {this.numberWithCommas(this.state.maxPrice)} Gs.
        </div>
      </div>
    );
  }

  filtersResults() {
    console.log(this.state.filtersResults);
    return (
      <div className="home-filters-body noPadding">
        <div
          style={{ padding: "20px 25px", fontSize: "24px", fontWeight: "600" }}
        >
          Lista de loteamientos
        </div>
        {this.state.filtersResults.length > 0 ? (
          this.state.filtersResults.map((d) => (
            <div
              key={d.id}
              onClick={() => this.setCurrentLoteamiento(d)}
              className={`home-filters-result shadow2 ${
                this.state.selectedLoteamiento?.id === d.id
                  ? "home-filters-result-selected"
                  : ""
              }`}
            >
              <div className="home-filters-resultTitle">
                {d.properties.name}
              </div>
              <div className="home-filters-resultBody">
                <div>
                  <div className="home-filters-resultSubtitle">
                    Departamento
                  </div>
                  <div style={{ fontWeight: "600" }}>
                    {
                      d.properties.developments.features[0].properties.district
                        .department.name
                    }
                  </div>
                </div>
                <div>
                  <div className="home-filters-resultSubtitle">Ciudad</div>
                  <div style={{ fontWeight: "600" }}>
                    {
                      d.properties.developments.features[0].properties.district
                        .name
                    }
                  </div>
                </div>
                <div>
                  <div className="home-filters-resultSubtitle">Lotes</div>
                  <div style={{ fontWeight: "600" }}>{d.total_parcels}</div>
                </div>
              </div>
              <div>
                <button className="home-filters-button">
                  {this.state.selectedLoteamiento?.id === d.id
                    ? "Seleccionado"
                    : "Ver en el mapa"}
                </button>
              </div>
            </div>
          ))
        ) : (
          <div style={{ padding: "0px 25px", fontSize: "20px", color: "#999" }}>
            No hay resultados...
          </div>
        )}
      </div>
    );
  }

  renderPreMapScreen() {
    return (
      <div className="home-background-image">
        <div className="home-background-color">
          <div className="home-header mobile-noDisplay">
            <img className="home-header-logo" src={inmoLogo2} alt="inmo"></img>
            <div onClick={this.goToProfile} className="home-header-profile">
              {localStorage.getItem("inmo_access_token") ? (
                <img src={profileIcon} width="32px" alt="profile"></img>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 600,
                  }}
                >
                  Ingresar
                </div>
              )}
            </div>
          </div>
          <img
            onClick={this.goToProfile}
            className="home-profileWhite desktop-noDisplay"
            src={profileIconW}
            alt="profile"
          ></img>
          <div className="home-container">
            <div className="home-column home-column-left">
              <div>
                <img
                  className="home-inmo-logo"
                  src={inmoLogo}
                  alt="inmo loteamientos"
                ></img>
              </div>
              <input
                onChange={this.search}
                className="home-input shadow1"
                type="text"
                placeholder="BUSCAR"
              ></input>
              <p className="home-input-description">
                Escribe el nombre de la ciudad o la fracción
              </p>
            </div>
            <div className="home-column home-column-right">
              <div
                onClick={() => this.setSearchSelected("devs")}
                className={`home-sugerencias ${
                  this.state.searchSelected === "devs"
                    ? "home-sugerencias-selected"
                    : ""
                }`}
              >
                Urbanizaciones
              </div>
              <div
                onClick={() => this.setSearchSelected("dists")}
                className={`home-sugerencias ${
                  this.state.searchSelected === "dists"
                    ? "home-sugerencias-selected"
                    : ""
                }`}
              >
                Ciudades
              </div>
              <div className="home-loteamientos-containerHeight">
                <div className="home-loteamientos-container">
                  {this.state.searchSelected === "devs" ? (
                    this.state.searchDevelopments.length > 0 ? (
                      this.state.searchDevelopments.map((l, index) => (
                        <div
                          key={l.id}
                          onClick={() => {
                            this.setLoteamiento(l.id);
                          }}
                          className={`home-loteamiento shadow1 home-loteamiento-${
                            index % 2 === 0 ? "left" : "right"
                          }`}
                        >
                          <div className="home-loteamiento-title">
                            {l.properties.name}
                          </div>
                          <div className="home-loteamiento-body">
                            {this.state.loteamientos ===
                            this.state.searchDevelopments
                              ? l.properties.developments.features[0].properties
                                  .district.name
                              : l.properties.developments.features[0].properties
                                  .district.name}
                          </div>
                        </div>
                      ))
                    ) : (
                      <h4 className="home-loteamientos-emptyState">
                        No se han encontrado fracciones que coincidan con tu
                        búsqueda...
                      </h4>
                    )
                  ) : this.state.searchDistricts.length > 0 ? (
                    this.state.searchDistricts.map((l, index) => (
                      <div
                        key={l.id}
                        onClick={() => this.setDistritoFromHome(l.id)}
                        className={`home-loteamiento shadow1 home-loteamiento-${
                          index % 2 === 0 ? "left" : "right"
                        }`}
                      >
                        <div className="home-loteamiento-title">{l.name}</div>
                        <div className="home-loteamiento-body">
                          {l.department.name}
                        </div>
                      </div>
                    ))
                  ) : (
                    <h4 className="home-loteamientos-emptyState">
                      No se han encontrado distritos que coincidan con tu
                      búsqueda...
                    </h4>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="home-politica">Política de Privacidad</div>
        </div>
      </div>
    );
  }

  renderMapScreen() {
    return (
      <div style={{ position: "relative" }}>
        <div className="home-header">
          <span onClick={this.goBack} className="home-back">
            <img className="home-back-arrow" src={back} alt="back"></img>
            <span className="home-back-text">Atrás</span>
          </span>
          <img className="home-header-logo" src={inmoLogo2} alt="inmo"></img>
          <div onClick={this.goToProfile} className="home-header-profile">
            {localStorage.getItem("inmo_access_token") ? (
              <img src={profileIcon} width="32px" alt="profile"></img>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 600,
                }}
              >
                Ingresar
              </div>
            )}
          </div>
        </div>

        <div
          className={
            "home-filters " +
            (this.state.filtersScreen ? "" : "mobile-noDisplay")
          }
        >
          <div className="home-filters-header desktop-noDisplay">
            <span className="home-filters-header-title">
              {this.state.filtersResults === null
                ? "Filtros"
                : "Lista de loteamientos"}
            </span>
            <img
              onClick={this.triggerFilters}
              className="home-filters-header-close"
              src={close}
              alt="X"
            ></img>
          </div>
          {this.state.filtersResults === null
            ? this.filtersBody()
            : this.filtersResults()}
          <button
            onClick={() => this.clearFiltersOrGoToFilters()}
            className={`home-filters-whiteButton${
              this.state.filtersResults ? "-fullwidth" : ""
            } shadow1`}
          >
            {this.state.filtersResults ? "Volver a filtros" : "Limpiar filtros"}
          </button>
          {!this.state.filtersResults && (
            <button
              disabled={this.state.filtersResults}
              onClick={() => this.applyFilters()}
              className="home-filters-redButton shadow1"
            >
              Aplicar Filtros
            </button>
          )}
        </div>

        <input
          className={`home-input home-mapInput desktop-noDisplay shadow1 ${
            this.state.filtersScreen ? "extraLength" : ""
          }`}
          type="text"
          placeholder="BUSCAR"
        ></input>
        <button
          onClick={this.triggerFilters}
          className="home-filtersButton desktop-noDisplay shadow1"
        >
          {!this.state.filtersScreen ? (
            <span>
              {this.state.filtersResults === null ? "Filtros" : "Listado"}
            </span>
          ) : (
            <img height="21px" src={search} alt="search"></img>
          )}
        </button>

        <div className="home-detailHeader">
          <div
            className="home-detailHeader-body"
            style={{ textAlign: "center" }}
          >
            <div></div>
            <div>
              <b className="mobile-noDisplay littleDesktop-noDisplay">
                Última actualización:{" "}
              </b>
              {this.state.lastUpdate}
            </div>
            <div></div>
          </div>
          {this.state.selectedLoteamiento ? (
            <div>
              <div className="home-detailHeader-title">
                {this.state.selectedLoteamiento.properties.name}
              </div>
              <div className="home-detailHeader-body">
                <div>
                  <b className="mobile-noDisplay littleDesktop-noDisplay">
                    Ciudad:
                  </b>{" "}
                  {
                    this.state.selectedLoteamiento.properties.developments
                      .features[0].properties.district.name
                  }
                </div>
                <div>
                  <b className="mobile-noDisplay littleDesktop-noDisplay">
                    Departamento:
                  </b>{" "}
                  {
                    this.state.selectedLoteamiento.properties.developments
                      .features[0].properties.district.department.name
                  }
                </div>
                <div>
                  <b className="mobile-noDisplay littleDesktop-noDisplay">
                    Lotes Disponibles:{" "}
                  </b>
                  {this.state.selectedLoteamiento.available_parcels} /{" "}
                  {this.state.selectedLoteamiento.total_parcels}
                  <span className="bigDesktop-noDisplay"> lotes</span>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ lineHeight: "50px" }}>
              Seleccione una fracción en el mapa
            </div>
          )}
        </div>

        <Map
          ref={this.map}
          unsetLoteamiento={this.unsetLoteamiento}
          setCurrentLoteamiento={this.setCurrentLoteamiento}
          name={this.state.selectedLoteamiento?.properties.name}
          phone={this.state.selectedLoteamiento?.properties.whatsapp_number}
          markers={this.state.mapList}
          currentLoteamientoId={this.state.selectedLoteamiento?.id}
          parcels={
            {
              type: "FeatureCollection",
              features: this.state.selectedLoteamiento
                ? [].concat(
                    ...this.state.selectedLoteamiento.properties.developments.features.map(
                      (f) => f.properties.parcels.features || []
                    )
                  )
                : [],
            }
            /*this.state.selectedLoteamiento?.properties.developments.features[0]
              .properties.parcels*/
          }
          left="var(--filters-width)"
          viewport={this.viewport}
          blocksCentralPoints={this.state.blocksCentralPoints}
        ></Map>
      </div>
    );
  }

  render() {
    if (this.state.selectedLoteamiento || this.state.mapList) {
      return this.renderMapScreen();
    } else {
      return this.renderPreMapScreen();
    }
  }
}

export default Home;
